import React from 'react'
import '../../style/Tickets.css';

function Tickets() {
    return (
        <div className = "tickets__page">
            <div className = "createticket__header">

            </div>
            <div className = "create-ticket__form">
                <div className = "create-ticket__title">
                    <div>
                        <label>Title</label>
                        <input type = "text" id = "title"/>
                    </div>
                    <div>
                        <label>Is Private</label>
                        <input type = "checkbox" id = "isPrivate"/>
                    </div>
                </div>
                <div>
                    
                </div>
            </div>            
        </div>
    )
}

export default Tickets
