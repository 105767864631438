import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Select, MenuItem, InputLabel, FormControl, Typography, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

var config = require('../Config/Config');

const useStyles = makeStyles((theme) => ({
  changeRegionContainer: {
    maxWidth: '400px',
    margin: '3rem auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: '1.5rem',
    borderRadius: '8px',
    boxSizing: 'border-box',
  },
  formControl: {
    margin: theme.spacing(2),
    width: '100%',
  },
  changeButton: {
    margin: theme.spacing(2, 0),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  error: {
    color: 'red',
    marginTop: '1rem',
    textAlign: 'center',
  },
  success: {
    color: 'green',
    marginTop: '1rem',
    textAlign: 'center',
  },
  currentRegionDisplay: {
    padding: theme.spacing(1, 2),
    borderRadius: '4px',
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(187, 134, 252, 0.15)',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
    boxSizing: 'border-box'
  },
  currentRegionText: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
}));

const ChangeRegion = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(props.userAuth.accountRegionID);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupSeverity, setPopupSeverity] = useState("success");

  const handleRegionChange = (e) => {
    setSelectedRegion(e.target.value);
  };

  const displayPopup = (severity, message) => {
    setPopupSeverity(severity);
    setPopupMessage(message);
    setPopupOpen(true);
  };

  const handleAPIError = (data) => {
    if (data.resultCode !== config.resultCodeTable.SUCCESS) 
    {
      displayPopup("error", data.results.error);
    } 
    else
    {
      props.setAuth({ ...props.userAuth, accountRegionID: selectedRegion });
      displayPopup("success", data.results.message);
    }
  };

  const postChangeRegion = () => {
    if (Number(selectedRegion) === Number(props.userAuth.accountRegionID)) 
    {
      displayPopup("error", "You are already in this region.");
      return;
    }

    setLoading(true);

    if (!config.isValidAccountRegion(selectedRegion)) 
    {
      displayPopup("error", "Invalid region.");
      setLoading(false);
      return;
    }

    fetch('/api/user/changeregion', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accountRegionID: selectedRegion }),
    })
    .then(res => res.json())
    .then(handleAPIError)
    .catch(() => displayPopup("error", "An error occurred while changing region. Please try again."))
    .finally(() => setLoading(false));
  };

  return (
    <>
      {config.isAllowed(props, config.accountLevelCodeTable.user) && (
        <div className={classes.changeRegionContainer}>
          
          <Typography variant="h6" component="div" className={classes.currentRegionText}>Current Region</Typography>
          <div className={classes.currentRegionDisplay}>
            {config.accountRegionCodeReverseLongTable[props.userAuth.accountRegionID]}
          </div>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>New Region</InputLabel>
            <Select
              value={selectedRegion}
              onChange={handleRegionChange}
              label="New Region"
            >
              {Object.keys(config.accountRegionCodeReverseLongTable).map((key) => (
                <MenuItem key={key} value={key}>
                  {config.accountRegionCodeReverseLongTable[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color='primary'
            className={classes.changeButton}
            onClick={postChangeRegion}
            disabled={loading || Number(selectedRegion) === Number(props.userAuth.accountRegionID)}
          >
            Change
            {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
          </Button>
        </div>
      )}
      <Snackbar 
        open={popupOpen} 
        autoHideDuration={3000} 
        onClose={() => setPopupOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
        <Alert onClose={() => setPopupOpen(false)} severity={popupSeverity} elevation={6} variant="filled">
            {popupMessage}
        </Alert>
      </Snackbar>
    </>
  );
};


export default ChangeRegion;
