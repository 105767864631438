import React from 'react';
import installer from '../../images/dnoinstaller.png';
import Header from '../Header';
import { URLs } from '../Data';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
  Button,
  Tooltip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import GetAppIcon from '@material-ui/icons/GetApp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    backgroundColor: "#1b1b1b",
    padding: theme.spacing(4, 0),
    minHeight: "100vh",
  },
  pageContainer: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#ffffff",
    padding: theme.spacing(4),
    width: "90%",
    [theme.breakpoints.up('sm')]: {
      width: "60%",
    },
    [theme.breakpoints.up('md')]: {
      width: "50%",
    },
    textAlign: "center",
    border: "2px solid #ffbb00",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#2c2c2c",
  },
  downloadTitle: {
    marginBottom: theme.spacing(2),
    color: "white",
  },
  buttonGroup: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      flexDirection: "row",
      justifyContent: "center",
    },
  },
  btnLink: {
    width: "100%",
    textDecoration: "none",
  },
  downloadAlternative: {
    marginTop: theme.spacing(3),
    color: "#ffffff",
  },
  systemRequirements: {
    marginTop: theme.spacing(4),
    background: '#1b1b1b',
    padding: theme.spacing(2),
    borderRadius: '5px',
    width: '100%',
    '& .MuiListItem-root': {
      justifyContent: 'center'
    },
    '& .MuiListItemText-root': {
      textAlign: 'center'
    },
  },
  installerPreview: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  installerImg: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      margin: '0 auto',
    },
  },
  linkStyle: {
    color: "#ffbb00",
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

function Download() {
  const classes = useStyles();
  const handleDownloadClick = (type) => {
  };

  return (
    <Container className={classes.root}>
      <Header />
      <div className={classes.pageContainer}>
        <Typography variant="h4" component="h1" className={classes.downloadTitle}>
          Download the Game
        </Typography>
        <div className={classes.buttonGroup}>
          <Tooltip title="Download the installer for the latest version">
            <a
              href={URLs.installer1}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.btnLink}
              aria-label="Download Installer"
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleDownloadClick("Installer")}
                size="large"
                fullWidth
                startIcon={<GetAppIcon />}
              >
                Installer
              </Button>
            </a>
          </Tooltip>
          <Tooltip title="Direct download without installer">
            <a
              href={URLs.directdl}
              target="_blank"
              rel="noreferrer"
              className={classes.btnLink}
              aria-label="Direct Download"
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleDownloadClick("Direct")}
                size="large"
                fullWidth
                startIcon={<CloudDownloadIcon />}
              >
                Direct Download
              </Button>
            </a>
          </Tooltip>
        </div>
        <div className={classes.downloadAlternative}>
          <Typography variant="body1">
            Having installer issues? Get help on{" "}
            <Link
              to={URLs.discord}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.linkStyle}
            >
              DNOrigins Discord
            </Link>{" "}
            or try the direct download.
          </Typography>
        </div>
        <div className={classes.installerPreview}>
          <Typography variant="body1" gutterBottom>
            Here's a quick look at the installer. Special thanks to Vahr for its creation!
          </Typography>
          <img
            src={installer}
            alt="DNOrigins Installer Preview"
            className={classes.installerImg}
          />
        </div>
        <div className={classes.systemRequirements}>
          <Typography variant="h5" gutterBottom style={{ color: "#ffbb00" }}>
            System Requirements
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Windows 10 or above (Other systems may work but are not supported)" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Intel® Core i5-4670 or higher" />
            </ListItem>
            <ListItem>
              <ListItemText primary="8GB RAM or higher" />
            </ListItem>
            <ListItem>
              <ListItemText primary="NVIDIA GeForce GTX 750" />
            </ListItem>
            <ListItem>
              <ListItemText primary="15GB of storage space" />
            </ListItem>
          </List>
        </div>
      </div>
    </Container>
  );
}

export default Download;
