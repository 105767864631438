import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';
import Modal from '../Modal';
import moment from 'moment';
import ReactLoading from 'react-loading';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const Container = styled.div`
  background-color: #1c1c1c;
  color: white;
  padding: 20px;
  border-radius: 10px;
  overflow-x: auto;
`;

const Title = styled.h1`
  color: #BB86FC;
  text-align: center;
  margin-bottom: 20px;
`;


const ResponsiveTable = styled.table`
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
  text-align: center;
  font-size: 0.75rem;

  th, td {
    padding: 7px;
    border: 1px solid #BB86FC;
    white-space: nowrap;
  }

  th {
    background-color: #BB86FC;
    color: rgba(20, 20, 20, 0.95);
  }

  tr {
    transition: background-color 0.3s ease;
  }

  tr:hover {
    background-color: rgba(40, 40, 40, 0.95);
  }

  td {
    color: white;
  }

  button {
    padding: 5px 10px;
    margin: 2px;
    background-color: #01A8A8;
    color: rgba(20, 20, 20, 0.95);
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #008080;
    }
  }

  @media (max-width: 768px) {
    thead {
      display: none;
    }

    tr {
      margin-bottom: 0.625rem;
      display: block;
      border: 1px solid #BB86FC;
    }

    td {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: right;
      position: relative;
      border-bottom: 1px solid #BB86FC;

      &::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        color: white;
      }
    }
  }
`;

function formatRawDate(date)
{
  return moment(date).utc().format('DD-MM-YYYY HH:mm');
}

function groupByEventRewardID(data) {
  const groupedData = data.reduce((acc, curr) => {
    if (!acc[curr.EventRewardID]) {
      acc[curr.EventRewardID] = {
        eventDetails: {},
        targets: [],
        items: [],
      };
    }

    acc[curr.EventRewardID].eventDetails = {
      ...acc[curr.EventRewardID].eventDetails,
      ...curr
    };

    if (curr.EventRewardTargetID && !acc[curr.EventRewardID].targets.some(target => target.EventRewardTargetID === curr.EventRewardTargetID)) {
      acc[curr.EventRewardID].targets.push({
        EventRewardTargetID: curr.EventRewardTargetID,
        AccountID: curr.AccountID,
        CharacterID: curr.CharacterID,
        AccountName: curr.AccountName,
        CharacterName: curr.CharacterName,
      });
    }

    if (curr.ItemID && !acc[curr.EventRewardID].items.some(item => item.RewardItemSequenceNumber === curr.RewardItemSequenceNumber)) {
      acc[curr.EventRewardID].items.push({
        RewardItemSequenceNumber: curr.RewardItemSequenceNumber,
        ItemID: curr.ItemID,
        ProductFlag: curr.ProductFlag,
        ItemCount: curr.ItemCount,
        ItemLevel: curr.ItemLevel,
        ItemDurability: curr.ItemDurability,
        ItemLifespan: curr.ItemLifespan,
        ItemOption: curr.ItemOption,
        ItemPotential: curr.ItemPotential,
        RandomSeed: curr.RandomSeed,
        EternityFlag: curr.EternityFlag,
        SealCount: curr.SealCount,
        DragonJewelType: curr.DragonJewelType,
      });
    }

    return acc;
  }, {});

  const sortedArray = Object.entries(groupedData).map(([EventRewardID, value]) => ({
    EventRewardID: parseInt(EventRewardID), // Convert to number
    ...value
  }));

  //Sort in descending order by EventRewardID
  sortedArray.sort((a, b) => b.EventRewardID - a.EventRewardID);

  return sortedArray;
}

function EventRewards() {
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState({ type: null, index: null });
  const [loading, setLoading] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupSeverity, setPopupSeverity] = useState("success");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
          closeModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    
    const fetchEventRewards = async () => {
      setLoading(true);
      try {
        const response = await fetch("/api/admin/alleventrewards", {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) throw new Error("Network response was not ok");
  
        const responseData = await response.json();
        const transformed = transformData(responseData.results.rows);
        const groupedData = groupByEventRewardID(transformed);
        setData(Object.values(groupedData));
      } 
      catch (error) 
      {
        console.error("Error fetching event rewards:", error);
      }
      setLoading(false);
    };

    fetchEventRewards();

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const transformData = (rows) => {
    return rows.map(row => {
      const obj = {};
      row.forEach(item => {
        obj[item.metadata.colName] = item.value;
      });
      return obj;
    });
  };

  const openItemModal = (index) => {
    setOpenModal({ type: 'item', index });
  };
  
  const openTargetModal = (index) => {
    setOpenModal({ type: 'target', index });
  };
  
  const closeModal = () => {
    setOpenModal({ type: null, index: null });
  };

  const copyToClipboard = (text, msg = null) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        if(msg)
        {
          setPopupSeverity("success");
          setPopupMessage(`Copied ${msg} to clipboard`);
          setPopupOpen(true);
        }
        console.log(`Copied "${text}" to clipboard`);
      })
      .catch(err => {
        if(msg)
        {
          setPopupSeverity("error");
          setPopupMessage(`Failed to copy ${msg} to clipboard`);
          setPopupOpen(true);
        }
        console.error(`Could not copy "${text}" to clipboard: `, err);
      });
  }
  
  const handleHeaderClick = (type, targets) => {
    if (type === "Account") 
    {
      const accountNames = targets.map(target => target.AccountName).join(",");
      copyToClipboard(accountNames, "Account List");
    } 
    else if (type === "Character") 
    {
      const characterNames = targets.map(target => target.CharacterName).join(",");
      copyToClipboard(characterNames, "Character List");
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'eventDetails.EventRewardID',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{value}</td>
      },
      {
        Header: 'Event Name',
        accessor: 'eventDetails.EventName',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{value}</td>
      },
      {
        Header: 'Sender',
        accessor: 'eventDetails.SenderName',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{value}</td>
      },
      {
        Header: 'Content',
        accessor: 'eventDetails.Content',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{value}</td>
      },
      {
        Header: 'Receive Type',
        accessor: 'eventDetails.ReceiveTypeCode',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{value}</td>
      },
      {
        Header: 'Target Type',
        accessor: 'eventDetails.TargetTypeCode',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{value}</td>
      },
      {
        Header: 'Reward Type',
        accessor: 'eventDetails.EventRewardTypeCode',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{value}</td>
      },
      {
        Header: 'Target Class',
        accessor: 'eventDetails.TargetClassCode',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{value}</td>
      },
      {
        Header: 'Min Level',
        accessor: 'eventDetails.TargetMinLevel',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{value}</td>
      },
      {
        Header: 'Max Level',
        accessor: 'eventDetails.TargetMaxLevel',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{value}</td>
      },
      {
        Header: 'Delete Flag',
        accessor: 'eventDetails.DeleteFlag',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{value}</td>
      },
      {
        Header: 'Start Date',
        accessor: 'eventDetails.ReserveSendDate',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{formatRawDate(value)}</td>
      },
      {
        Header: 'End Date',
        accessor: 'eventDetails.ExpirationDate',
        Cell: ({ value, column, row }) => <td data-label={column.Header} key={row.id}>{formatRawDate(value)}</td>
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: d => d,
        Cell: (props) => {
          const rowIndex = props.row.index;
          return (
            <td data-label="Actions" key = {rowIndex}>
              <div>
                <button onClick={() => openTargetModal(rowIndex)}>View Targets</button>
                <button onClick={() => openItemModal(rowIndex)}>View Items</button>
              </div>
            </td>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <Container>
      <Title>Event Rewards</Title>
      {loading ? (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <ReactLoading type="bubbles" height={"128px"} width={"128px"} />
        </div>
      ) : (
        <ResponsiveTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key = {index}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={i}>
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => cell.render('Cell'))} 
                </tr>
                <Modal 
                  isOpen={openModal.type === 'target' && openModal.index === i} 
                  onClose={closeModal} 
                  title="Event Targets"
                  maxWidth={'600px'}
                >
                  <div style={{ margin: '20px 0'}}>
                    <ResponsiveTable>
                        <thead>
                            <tr>
                                <th>Index</th>
                                <th onClick={() => handleHeaderClick("Account", row.original.targets)}>Account</th>
                                <th onClick={() => handleHeaderClick("Character", row.original.targets)}>Character</th>
                            </tr>
                        </thead>
                        <tbody>
                            {row.original.targets.map(target => (
                                <tr key={target.EventRewardTargetID}>
                                    <td data-label="Index">{target.EventRewardTargetID}</td>
                                    <td data-label="Account">{target.AccountID} - {target.AccountName}</td>
                                    <td data-label="Character">{target.CharacterID} - {target.CharacterName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </ResponsiveTable>
                  </div>
              </Modal>
              <Modal 
                isOpen={openModal.type === 'item' && openModal.index === i} 
                onClose={closeModal} 
                title="Event Items"
              >
                <div style={{ margin: '20px 0'}}>
                  <ResponsiveTable>
                  <thead>
                      <tr>
                        <th>Index</th>
                        <th>Item ID</th>
                        <th>Product Flag</th>
                        <th>Item Count</th>
                        <th>Item Level</th>
                        <th>Item Durability</th>
                        <th>Item Lifespan</th>
                        <th>Item Option</th>
                        <th>Item Potential</th>
                        <th>Random Seed</th>
                        <th>Seal Count</th>
                        <th>Eternity Flag</th>
                        <th>Jewel Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.original.items.map(item => (
                        <tr key={item.RewardItemSequenceNumber}>
                          <td data-label="Index">{item.RewardItemSequenceNumber}</td>
                          <td data-label="Item ID">{item.ItemID}</td>
                          <td data-label="Product Flag">{item.ProductFlag ? "true" : "false"}</td>
                          <td data-label="Item Count">{item.ItemCount}</td>
                          <td data-label="Item Level">{item.ItemLevel}</td>
                          <td data-label="Item Durability">{item.ItemDurability}</td>
                          <td data-label="Item Lifespan">{item.ItemLifespan}</td>
                          <td data-label="Item Option">{item.ItemOption}</td>
                          <td data-label="Item Potential">{item.ItemPotential}</td>
                          <td data-label="Random Seed">{item.RandomSeed}</td>
                          <td data-label="Seal Count">{item.SealCount}</td>
                          <td data-label="Eternity Flag">{item.EternityFlag ? "true" : "false"}</td>
                          <td data-label="Jewel Type">{item.DragonJewelType}</td>
                        </tr>
                      ))}
                    </tbody>
                  </ResponsiveTable>
                </div>
              </Modal>
              </React.Fragment>
            );
          })}
        </tbody>
      </ResponsiveTable>
      )}
        <Snackbar 
        open={popupOpen} 
        autoHideDuration={3000} 
        onClose={() => setPopupOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
        <Alert onClose={() => setPopupOpen(false)} severity={popupSeverity} elevation={6} variant="filled">
            {popupMessage}
        </Alert>
        </Snackbar>
    </Container>
  );
}

export default EventRewards;

