import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";
import { regions } from './Data'
import worldData from './world.json';

//const geoUrl =
//  "https://raw.githubusercontent.com/johan/world.geo.json/master/countries.geo.json";

const MapChart = () => {
  return (
    <ComposableMap>
      <Geographies geography = {worldData}>
        {({ geographies }) =>
          geographies
            .map(geo => (
              <Geography
                key = {geo.rsmKey}
                geography = {geo}
                fill = "#BB86FC"
                stroke = "#3700B3"
                style={{
                  default: { outline: "none" },
                  hover: { outline: "none" },
                  pressed: { outline: "none" },
                }}
              />
            ))
        }
      </Geographies>
      {regions.map(({ name, coordinates, markerOffset }) => (
        <Marker key = {name} coordinates = {coordinates}>
          <g
            fill = "none"
            stroke = "#FF5533"
            strokeWidth = "3"
            strokeLinecap = "round"
            strokeLinejoin = "round"
            transform = "translate(-12, -24)"
          >
            <circle cx = "12" cy = "10" r = "3" />
            <path d = "M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
          <text
            textAnchor = "middle"
            y = {markerOffset}
            style = {{ fontFamily: "system-ui", fill: "#ffbb00", fontSize: "1.7rem", fontWeight: "bold", textShadow: "2px 0 0 #FF5533, -2px 0 0 #FF5533, 0 2px 0 #FF5533, 0 -2px 0 #FF5533, 1px 1px #FF5533, -1px -1px 0 #FF5533, 1px -1px 0 #FF5533, -1px 1px 0 #FF5533"}}
          >
            {name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default MapChart;
