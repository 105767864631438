///////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// VARIABLES //////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////

export const accountLevelCodeTable = {
    user: 0,
    moderator: 1,
    admin: 99
}

export const accountRegionCodeTable = {
    EUW: 1,
    NAW: 2,
    NAE: 3,
    SEA: 4,
    SA: 5,
    HK: 6,
    MIN: 1,
    MAX: 6
}

export const accountRegionCodeReverseTable = {
    1: "EUW",
    2: "NAW",
    3: "NAE",
    4: "SEA",
    5: "SA",
    6: "HK",
}

export const accountRegionCodeReverseLongTable = {
    1: "Europe West (EUW)",
    2: "North America West (NAW)",
    3: "North America East (NAE)",
    4: "South East Asia (SEA)",
    5: "South America (SA)",
    6: "Honk Kong (HK)",
}

export const resultCodeTable = {
    SUCCESS: 200,
    FAILURE: 401
}

///////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// FUNCTIONS //////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////
export const MAXIDLENGTH = 16;
export const MINIDLENGTH = 3;
export const MAXPASSLENGTH = 16;
export const MINPASSLENGTH = 3;

export function isLoggedInApp(userAuth) {
    //console.log("user : " + JSON.stringify(userAuth));
    if (userAuth && userAuth.accountID > 0) {
        return true;
    }
    return false;
}

export function isAllowedApp(userAuth, permissionRequirement) {
    if (isLoggedInApp(userAuth) && userAuth.accountLevelCode >= permissionRequirement)
    {
        return true;
    }
    return false;
}

export function isLoggedIn(props) {
    //console.log("user : " + JSON.stringify(props.user));
    if (props.userAuth && props.userAuth.accountID > 0) {
        return true;
    }
    return false;
}

export function isAllowed(props, permissionRequirement) {
    if (isLoggedIn(props) && props.userAuth.accountLevelCode >= permissionRequirement)
    {
        return true;
    }
    return false;
}

export function isValidUsername(username){
    var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/~?]/;
    if(username && username.length > MINIDLENGTH && username.length < MAXIDLENGTH && !format.test(username))
    {
        return true;
    }
    return false;
}

export function isValidPassword(password) {
    var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/~?]/;
    if (password && password.length > MINPASSLENGTH && password.length < MAXPASSLENGTH && !format.test(password)) {
        return true;
    }
    return false;
}

export function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function isValidAccountRegion(region) {
    if (region >= accountRegionCodeTable.MIN && region <= accountRegionCodeTable.MAX) {
        return true;
    }
    return false;
}

export function createRegionComboBox() {
    const options = [];

    for (var i=accountRegionCodeTable.MIN; i<= accountRegionCodeTable.MAX; i++) {
        options.push({value: i, description: accountRegionCodeReverseLongTable[i]});
    }

    return (
      <select id = "region">
        {options.map((option, index) => (
          <option key = {index} value = {option.value}>{option.description}</option>
        ))}
      </select>
    )
}

export function isEmptyObject(object) {
    return Object.keys(object).length === 0;
}

export function formValidation(data) 
{
    let errors = {};
  
    if (!isValidUsername(data.username)) 
    {
      errors.username = "Username is incorrect, must be between " + (MINIDLENGTH + 1) + " and " + (MAXIDLENGTH - 1) + " characters long and not contain any special character.";
    }
    if (!isValidPassword(data.password)) 
    {
      errors.password = "Password is incorrect, must be between " + (MINPASSLENGTH + 1) + " and " + (MAXPASSLENGTH - 1) + " characters long and not contain any special character.";
    }
    if (data.password !== data.confirmPassword) 
    {
      errors.confirmPassword = "Passwords do not match.";
    }
    if (!isValidEmail(data.email)) 
    {
      errors.email = "The email format is incorrect.";
    }
    if (data.email !== data.confirmEmail) 
    {
      errors.confirmEmail = "Emails do not match.";
    }
    if (!isValidAccountRegion(data.region)) 
    {
      errors.region = "Account Region is invalid.";
    }
  
    return errors;
}
  