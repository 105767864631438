import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { RiUserSettingsLine, RiAdminLine } from 'react-icons/ri';
import LazyLoad from 'react-lazyload';

import LoginButton from './Login/LoginButton';
import SignupButton from './Login/SignupButton';
import LogoutButton from './LogoutButton';

import '../style/Navbar.css';

const config = require('./Config/Config');

function Navbar(props) {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [time, setTime] = useState(new Date().toLocaleString('en-GB', { timeZone: 'CET' }));

    const updateServerTime = () => {
        setTime(new Date().toLocaleString('en-GB', { timeZone: 'CET' }));
    };

    useEffect(() => {
        const interval = setInterval(updateServerTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
        window.addEventListener('resize', showButton);
        return () => {
            window.removeEventListener('resize', showButton);
        };
    }, []);

    return (
        <IconContext.Provider value={{ color: '#fff' }}>
            <div className="navbar">
                <div className="navbar-container container">
                    <div className="navbar__left" style={{ visibility: click ? 'hidden' : 'visible' }}>
                        <div className="time__title">
                            <h3>Server Time</h3>
                        </div>
                        <div className="time__container">
                            <span>{time.replace(",", " -")}</span>
                            <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                                <LazyLoad>
                                    <img src={`${process.env.PUBLIC_URL}/images/logov2.png`} alt="navbar_logo" />
                                </LazyLoad>
                            </Link>
                        </div>
                    </div>
                    <div className="menu-icon" onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        {["Home", "Download", "Donate", "News"].map((navItem, index) => (
                            <li key={index} className="nav-item">
                                <Link 
                                    to={navItem === "Home" ? "/" : `/${navItem.toLowerCase()}`} 
                                    className="nav-links" 
                                    onClick={closeMobileMenu}
                                >
                                    {navItem}
                                </Link>
                            </li>
                        ))}
                        {config.isLoggedIn(props) ? (
                            <>
                                {button && config.isAllowed(props, config.accountLevelCodeTable.moderator) && (
                                    <li className="nav-btn">
                                        <Link to='/admin' className="nav-links" onClick={closeMobileMenu}>
                                            <RiAdminLine color="#F7AB00" />
                                        </Link>
                                    </li>
                                )}
                                <li className="nav-btn">
                                    <Link to='/user' className="nav-links" onClick={closeMobileMenu}>
                                        <RiUserSettingsLine color="#F7AB00" />
                                    </Link>
                                </li>
                                <li className="nav-btn">
                                    <LogoutButton setAuth={props.setAuth} buttonStyle="btn--outline" buttonSize={button ? undefined : 'btn--mobile'} />
                                </li>
                            </>
                        ) : (
                            <>
                                <li className="nav-btn">
                                    <SignupButton setAuth={props.setAuth} buttonStyle="btn--outline" buttonSize={button ? undefined : 'btn--mobile'} onClick={closeMobileMenu} />
                                </li>
                                <li className="nav-btn">
                                    <LoginButton setAuth={props.setAuth} buttonStyle="btn--outline" buttonSize={button ? undefined : 'btn--mobile'} onClick={closeMobileMenu} />
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
            <div className={click ? 'the-blur active' : 'the-blur'}></div>
        </IconContext.Provider>
    );
}

export default Navbar;
