import React from 'react';
import '../style/Footer.css';
import { Link } from 'react-router-dom';
import {
  FaYoutube,
  FaDiscord,
} from 'react-icons/fa';
import {URLs} from './Data'
import LazyLoad from 'react-lazyload';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className = 'social-logo'>
              <LazyLoad>
                <img src = {process.env.PUBLIC_URL + "/images/dnofull_logo.png" } alt = "dnofull_logo"/>
              </LazyLoad>
            </Link>
            <small className='website-rights'>DNOrigins © 2023</small>
          </div>
          
          <div className='social-icons'>
            <div>
              <h3>Community Discord</h3>
              <Link
                className='social-icon-link'
                to = {URLs.discord}
                target='_blank'
                aria-label='Discord'
              >
                <FaDiscord />
              </Link>
            </div>
            <div>
              <h3>Youtube Channel</h3>
              <Link
                className='social-icon-link'
                to = {URLs.youtube}
                target='_blank'
                aria-label='Youtube'
              >
                <FaYoutube />
              </Link>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;