import React, { useState } from 'react'
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import '../../style/Admin.css';
var config = require('../Config/Config');

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function SendMail(props) {
  const classes = useStyles();
  const [sendMailFailed, setSendMailFailed] = useState(false);
  const [sendMailSuccess, setSendMailSuccess] = useState({});
  const [senderName, setSenderName] = useState("DNO Staff");
  const [receivers, setReceivers] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [goldAmount, setGoldAmount] = useState(0);
  const [items, setItems] = useState([]);
  const [mailType, setMailType] = useState("");

  const handleAddItem = () => {
    if (items.length >= 5) 
    {
      return; // Do not add more items if the 5 limit is reached
    }
    setItems([...items, {
      id: "",
      count: 1,
      durability: 0,
      randseed: 0,
      enhanceLevel: 0,
      potential: 0,
      soulBound: 1,
      sealCount: 0,
      option: 0,
      eternity: 1,
      lifespan: 0
    }]);
  };

  const handleChangeItem = (index, key, value) => {
    const newItems = [...items];
    newItems[index][key] = value;
    setItems(newItems);
  };

  const handleRemoveItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    fetch("/api/admin/sendmail", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({senderName, receivers, subject, content, goldAmount, items, mailType})
    })
    .then(res => res.json())
    .then(data => {
      if (data.resultCode !== config.resultCodeTable.SUCCESS) {
          setSendMailFailed(true);
      } else {
          setSendMailFailed(false);
          setSendMailSuccess(data);
      }
    });
  };

  return (
    <div className="send__mail">
      <h1 className="send__mail-title">Send Mails</h1>
      {config.isAllowed(props, config.accountLevelCodeTable.moderator) && (
        <>
          {config.isEmptyObject(sendMailSuccess) ? (
            <>
              <TextField className={classes.input} value={senderName} onChange={e => setSenderName(e.target.value)} label="Sender Name" variant="outlined" />
              <TextField className={classes.input} value={receivers} onChange={e => setReceivers(e.target.value)} label="Receivers (comma separated)" variant="outlined" />
              <TextField className={classes.input} value={subject} onChange={e => setSubject(e.target.value)} label="Subject" variant="outlined" />
              <TextField className={classes.input} value={content} onChange={e => setContent(e.target.value)} label="Content" variant="outlined" multiline minRows={4} />
              <TextField className={classes.input} value={goldAmount} onChange={e => setGoldAmount(e.target.value)} label="Gold Amount" type="number" variant="outlined" />
              <TextField className={classes.input} value={mailType} onChange={e => setMailType(e.target.value)} label="Mail Type" type="number" variant="outlined" placeholder="0 = All, 1 = Character, 2 = Contents, 3 = Event, 4 = System"/>
              
              <h3>Items ({items.length})</h3>
              {items.map((item, index) => (
                <CollapsibleItemForm key={index} index={index} item={item} onChange={handleChangeItem} onRemove={handleRemoveItem} />
              ))}
              <Button className={classes.button} variant="contained" color="primary" onClick={handleAddItem} disabled = {items.length >= 5}>Add Item</Button>

              <Button className={classes.button} variant="contained" color="secondary" onClick={handleSubmit}>Send Mail</Button>
              {sendMailFailed && <p>Mail sending failed.</p>}
            </>
          ) : (
            <>
            <p>{sendMailSuccess.results.message}</p>
            <Button className={classes.button} variant="contained" color="secondary" onClick={() => {setSendMailSuccess({})}}>Send Another Mail</Button>
            </>          
          )}
        </>
      )}
    </div>
  );
}

function CollapsibleItemForm({ index, item, onChange, onRemove }) {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);
  
  const handleChange = (key, value) => {
    onChange(index, key, value);
  };

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Button className={classes.button} variant="contained" color="primary" onClick={handleToggleCollapse}>
        {collapsed ? `Show Item ${index + 1}` : `Hide Item ${index + 1}`}
      </Button>
      <Collapse in={!collapsed}>
        <div>
          <TextField className={classes.input} value={item.id} onChange={e => handleChange('id', e.target.value)} label="Item ID" variant="outlined" />
          <TextField className={classes.input} value={item.count} onChange={e => handleChange('count', e.target.value)} label="Count" type="number" variant="outlined" />
          <TextField className={classes.input} value={item.durability} onChange={e => handleChange('durability', e.target.value)} label="Durability" type="number" variant="outlined" />
          <TextField className={classes.input} value={item.randseed} onChange={e => handleChange('randseed', e.target.value)} label="Randseed" type="number" variant="outlined" />
          <TextField className={classes.input} value={item.enhanceLevel} onChange={e => handleChange('enhanceLevel', e.target.value)} label="Enhance Level" type="number" variant="outlined" />
          <TextField className={classes.input} value={item.potential} onChange={e => handleChange('potential', e.target.value)} label="Potential" type="number" variant="outlined" />
          <TextField className={classes.input} value={item.soulBound} onChange={e => handleChange('soulBound', e.target.value)} label="Soul Bound" type="number" variant="outlined" />
          <TextField className={classes.input} value={item.sealCount} onChange={e => handleChange('sealCount', e.target.value)} label="Seal Count" type="number" variant="outlined" />
          <TextField className={classes.input} value={item.option} onChange={e => handleChange('option', e.target.value)} label="Option" type="number" variant="outlined" />
          <TextField className={classes.input} value={item.eternity} onChange={e => handleChange('eternity', e.target.value)} label="Eternity" type="number" variant="outlined" />
          <TextField className={classes.input} value={item.lifespan} onChange={e => handleChange('lifespan', e.target.value)} label="Lifespan" type="number" variant="outlined" />
          <Button className={classes.button} variant="contained" color="secondary" onClick={() => onRemove(index)}>Remove</Button>
        </div>
      </Collapse>
    </div>
  );
}
