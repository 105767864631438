import React from 'react'
import HeroSection from './HeroSection'
import { header, multiserver } from './Data'
import Faq from './Faq';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import NewsSlider from './NewsSlider';

//Images
// import qol from '../images/carousel/compressed/image4_qol.jpg'
// import srb from '../images/carousel/compressed/image2_speedrunboard.jpg'
// import marriage from '../images/carousel/compressed/image1_marriage.jpg'
// import discord from '../images/carousel/compressed/image3_discordintegr.jpg'
// import mserver from '../images/carousel/compressed/image4_multiserverclient.jpg'

function Home() {
    return (
        <div className = "page__content">
            <HeroSection {...header}/>
            <NewsSlider />
            <HeroSection {...multiserver}/>
            <Faq/>
        </div>
    )
}

export default Home
