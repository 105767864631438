import React from "react";
import Faq from "react-faq-component";
import { FaqData } from './Data';
import '../style/Faq.css';
const data = {
    title: "FAQ",
    rows: FaqData,
};

const styles = {
    bgColor: '#121212',
    titleTextColor: "#F7AB00",
    rowTitleColor: "#BB86FC",
    rowContentColor: '#F7AB00',
    arrowColor: "#F7AB00",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

function FaqComp() {
    return (
        <div className = "faq__container">
            <Faq
                data = {data}
                styles = {styles}
                config = {config}
            />
        </div>
    );
}
export default FaqComp;


// import React, { useState } from 'react';
// import { FaqData } from './Data';
// import styled from 'styled-components';
// import { IconContext } from 'react-icons';
// import { FiPlus, FiMinus } from 'react-icons/fi';

// const AccordionSection = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   position: relative;
//   height: 100%;
//   width: 100%;
//   background: #A06923;
// `;

// const Container = styled.div`
//   position: absolute;
//   top: 30%;
//   width: 100%;
//   box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
// `;

// const Wrap = styled.div`
//   background: #272727;
//   color: #fff;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   text-align: center;
//   cursor: pointer;
//   h1 {
//     padding: 2rem;
//     font-size: 2rem;
//   }
//   span {
//     margin-right: 1.5rem;
//   }
// `;

// const Dropdown = styled.div`
//   background: #1c1c1c;
//   color: #00ffb9;
//   width: 100%;
//   height: 100px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   border-bottom: 1px solid #00ffb9;
//   border-top: 1px solid #00ffb9;
//   p {
//     font-size: 2rem;
//   }
// `;

// const Accordion = () => {
//   const [clicked, setClicked] = useState(false);

//   const toggle = index => {
//     if (clicked === index) {
//       //if clicked question is already active, then close it
//       return setClicked(null);
//     }

//     setClicked(index);
//   };

//   return (
//     <IconContext.Provider value = {{ color: '#00FFB9', size: '25px' }}>
//       <AccordionSection>
//         <Container>
//           {FaqData.map((item, index) => {
//             return (
//               <>
//                 <Wrap onClick = {() => toggle(index)} key = {index}>
//                   <h1>{item.question}</h1>
//                   <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
//                 </Wrap>
//                 {clicked === index ? (
//                   <Dropdown>
//                     <p>{item.answer}</p>
//                   </Dropdown>
//                 ) : null}
//               </>
//             );
//           })}
//         </Container>
//       </AccordionSection>
//     </IconContext.Provider>
//   );
// };

// export default Accordion;
