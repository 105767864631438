import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#BB86FC',
    },
    secondary: {
      main: '#ffbb00',
    },
    background: {
      default: 'rgba(20, 20, 20, 0.95)',
      paper: 'rgba(25, 25, 25, 0.95)',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: "#ffbb00"
        },
        "&$focused $notchedOutline": {
          borderColor: "#BB86FC"
        }
      }
    }
  }
});

export default theme;
