import React from 'react'
import {Button} from './Button'
import {Link} from 'react-router-dom'
import '../style/HeroSection.css'
import MapChart from './MapChart'
import LazyLoad from 'react-lazyload';
function HeroSection(props) {
    return (
        <>
            <div className = {props.hasSlider ? 'home__hero-section sliderBg' : props.hasMap ? 'home__hero-section mapBg' : 'home__hero-section darkBg'}>
                <div className = 'container'>
                    <div className = 'row home__hero-row' style = {{display: 'flex', flexDirection: props.imgStart === 'start' ? 'row-reverse' : 'row'}}>
                        <div className = "col">
                            <div className = "home__hero-text-wrapper">
                                <div className = {props.lightTitle ? "top-line" : "top-line-dark"}>{props.topLine}</div>
                                    <h1 className = {props.lightText ? 'heading' : 'heading dark'}>{props.headline}</h1>
                                    <p className = {props.lightTextDesc ? 'home__hero-subtitle' : 'home__hero-subtitle dark'}>{props.description}</p>
                                    {props.hideButton ? null : <>
                                    <Link to = "/sign-up">
                                        <Button buttonSize = 'btn--wide' buttonColor = 'blue'>{props.buttonLabel}</Button>
                                    </Link>
                                    </>}
                            </div>
                        </div>
                        <div className = "col">
                                <div className = "home__hero-img-wrapper">
                                    {props.hasMap ? <MapChart/> : <LazyLoad><img src = {process.env.PUBLIC_URL + "/images/" + props.img} alt = {props.alt} className = "home__hero-img"/></LazyLoad>}
                                </div>                                
                        </div>
                    </div>
                </div>  
            </div>     
        </>
    )
}

export default HeroSection
