import React, { useState } from 'react';
import queryString from 'query-string';
import { Button, TextField, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

var config = require('./Config/Config');

const useStyles = makeStyles((theme) => ({
    resetPasswordContainer: {
      maxWidth: '400px',
      margin: '3rem auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      padding: '1.5rem',
      borderRadius: '8px',
      boxSizing: 'border-box',
    },
    textField: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    setButton: {
      marginTop: 20,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  }));

function ResetPass(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupSeverity, setPopupSeverity] = useState("error");

  var params = queryString.parse(props.location.search);
  const accountName = params.accountName;
  const token = params.token;

  const displayPopup = (severity, message) => {
    setPopupSeverity(severity);
    setPopupMessage(message);
    setPopupOpen(true);
  };

  const SetNewPassword = async () => {
    setLoading(true);

    if (password !== confirmPassword) 
    {
      displayPopup("error", "Password and Confirm Password fields do not match.");
      setLoading(false);
      return;
    }

    try 
    {
      const response = await fetch("/api/user/resetpassvalidate", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: token,
          accountName: accountName,
          newPassword: password
        })
      });

      const data = await response.json();
      displayPopup(data.resultCode === config.resultCodeTable.SUCCESS ? "success" : "error", 
                   data.results.error || data.results.message);
    } 
    catch (error) 
    {
      displayPopup("error", error.message || "An error occurred. Please try again.");
    } 
    finally 
    {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.resetPasswordContainer}>
        <TextField
          className={classes.textField}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="New Password"
          variant="outlined"
          type="password"
          placeholder="Enter new password"
          margin="normal"
        />

        <TextField
          className={classes.textField}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          label="Confirm New Password"
          variant="outlined"
          type="password"
          placeholder="Confirm new password"
          margin="normal"
        />

        <Button
          variant="contained"
          color="primary"
          className={classes.setButton}
          disabled={loading || password === "" || confirmPassword === "" || popupSeverity === "success"}
          onClick={SetNewPassword}
        >
          Set New Password
          {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
        </Button>
      </div>
      <Snackbar
        open={popupOpen}
        autoHideDuration={3000}
        onClose={() => setPopupOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setPopupOpen(false)} severity={popupSeverity} elevation={6} variant="filled">
          {popupMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ResetPass;
