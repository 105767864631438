import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  width: 80%;
  max-width: ${props => props.maxWidth ? props.maxWidth : '1200px'};
  max-height: 90vh;
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ModalHeader = styled.h2`
  color: purple;
  text-align: center;
  margin-bottom: 20px;
`;

const ModalContent = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

function Modal({ isOpen, onClose, title, children, maxWidth }) {
    if (!isOpen) return null;
    
    return (
    <Overlay>
        <ModalWrapper maxWidth={maxWidth}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ModalHeader>{title}</ModalHeader>
        <ModalContent>{children}</ModalContent>
        </ModalWrapper>
    </Overlay>
    );
}

export default Modal;
