import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import moment from 'moment';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Snackbar,
  useMediaQuery,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import HistoryIcon from '@material-ui/icons/History';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const config = require('../Config/Config');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Roboto, sans-serif',
    backgroundColor: '#1b1b1b',
    padding: theme.spacing(4, 0),
    minHeight: '100vh',
  },
  pageContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#ffffff',
    padding: theme.spacing(4),
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    textAlign: 'center',
    border: '2px solid #ffbb00',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#2c2c2c',
  },
  title: {
    color: '#ffbb00',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    backgroundColor: '#2c2c2c',
    borderRadius: theme.spacing(1),
  },
  table: {
    minWidth: 650,
    '& th': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
    },
    '& td': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(25, 25, 25, 0.8)',
    },
  },
  noData: {
    color: '#ffffff',
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
  card: {
    backgroundColor: '#2c2c2c',
    color: '#ffffff',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  cardField: {
    marginBottom: theme.spacing(1),
  },
  tableSortLabel: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#ffffff',
    },
    '&.MuiTableSortLabel-active': {
      color: '#ffffff',
    },
    '& .MuiTableSortLabel-icon': {
      color: '#ffffff',
    },
  },
}));

const PurchaseHistory = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [state, setState] = useState({
    loading: true,
    error: null,
    purchases: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      try 
      {
        const response = await fetch('/api/user/purchasehistory', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (data.resultCode !== config.resultCodeTable.SUCCESS) {
          setState({ loading: false, error: true, purchases: [] });
          setSnackbarSeverity('error');
          setSnackbarMessage('Failed to load purchase history.');
          setSnackbarOpen(true);
        } else {
          setState({
            loading: false,
            error: false,
            purchases: data.results.rows.reverse(),
          });
        }
      } 
      catch (error) 
      {
        console.error('Error fetching purchase history:', error);
        setSnackbarSeverity('error');
        setSnackbarMessage('An error has occurred. Please try again later.');
        setSnackbarOpen(true);
        setState({ loading: false, error: true, purchases: [] });
      }
    };

    fetchPurchaseHistory();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orderResult = comparator(a[0], b[0]);
      if (orderResult !== 0) return orderResult;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    let aValue, bValue;
    switch (orderBy) {
      case 'date':
        aValue = new Date(a[1].value);
        bValue = new Date(b[1].value);
        break;
      case 'oc':
        aValue = a[5].value;
        bValue = b[5].value;
        break;
      case 'transactionID':
        aValue = a[3].value;
        bValue = b[3].value;
        break;
      case 'amount':
        aValue = a[7].value; // EURAmount
        bValue = b[7].value;
        break;
      case 'status':
        aValue = a[8].value;
        bValue = b[8].value;
        break;
      default:
        aValue = a[1].value;
        bValue = b[1].value;
    }

    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const renderTable = () => {
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, state.purchases.length - page * rowsPerPage);

    return (
      <>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table} aria-label="purchase history table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'date'}
                    direction={orderBy === 'date' ? order : 'asc'}
                    onClick={() => handleRequestSort('date')}
                    className={classes.tableSortLabel}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'oc'}
                    direction={orderBy === 'oc' ? order : 'asc'}
                    onClick={() => handleRequestSort('oc')}
                    className={classes.tableSortLabel}
                  >
                    Origins Cash
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'transactionID'}
                    direction={orderBy === 'transactionID' ? order : 'asc'}
                    onClick={() => handleRequestSort('transactionID')}
                    className={classes.tableSortLabel}
                  >
                    Transaction ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'amount'}
                    direction={orderBy === 'amount' ? order : 'asc'}
                    onClick={() => handleRequestSort('amount')}
                    className={classes.tableSortLabel}
                  >
                    Amount
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={orderBy === 'status' ? order : 'asc'}
                    onClick={() => handleRequestSort('status')}
                    className={classes.tableSortLabel}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(state.purchases, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((purchase, index) => {
                  const purchaseDate = moment(purchase[1].value).format(
                    'DD-MM-YYYY HH:mm'
                  );
                  const OCAmount = purchase[5].value;
                  const currencyShort = purchase[6].value;
                  const currencyAmount = purchase[2].value;
                  const EURAmount = purchase[7].value;
                  const transactionID = purchase[3].value;
                  const status = purchase[8].value;

                  let amountText = `${EURAmount.toLocaleString()}€`;
                  if (currencyShort !== 'EUR') {
                    amountText = `${currencyAmount.toLocaleString()} ${currencyShort} (${EURAmount.toLocaleString()}€)`;
                  }

                  return (
                    <TableRow key={index} className={classes.tableRow} hover>
                      <TableCell>{purchaseDate}</TableCell>
                      <TableCell>{OCAmount.toLocaleString()}</TableCell>
                      <TableCell>{transactionID}</TableCell>
                      <TableCell>{amountText}</TableCell>
                      <TableCell>{status}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={state.purchases.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Rows per page:"
          style={{ color: '#ffffff' }}
        />
      </>
    );
  };

  const renderCards = () => {
    return (
      <Grid container spacing={2}>
        {state.purchases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((purchase, index) => {
          const purchaseDate = moment(purchase[1].value).format('DD-MM-YYYY HH:mm');
          const OCAmount = purchase[5].value;
          const currencyShort = purchase[6].value;
          const currencyAmount = purchase[2].value;
          const EURAmount = purchase[7].value;
          const transactionID = purchase[3].value;
          const status = purchase[8].value;

          let amountText = `${EURAmount.toLocaleString()}€`;
          if (currencyShort !== 'EUR') {
            amountText = `${currencyAmount.toLocaleString()} ${currencyShort} (${EURAmount.toLocaleString()}€)`;
          }

          return (
            <Grid item xs={12} key={index}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="body1" className={classes.cardField}>
                    <strong>Date:</strong> {purchaseDate}
                  </Typography>
                  <Typography variant="body1" className={classes.cardField}>
                    <strong>Origins Cash:</strong> {OCAmount.toLocaleString()}
                  </Typography>
                  <Typography variant="body1" className={classes.cardField}>
                    <strong>Transaction ID:</strong> {transactionID}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Amount:</strong> {amountText}
                  </Typography>
                  <Typography variant="body1" className={classes.cardField}>
                    <strong>Status:</strong> {status}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
        {rowsPerPage - Math.min(rowsPerPage, state.purchases.length - page * rowsPerPage) > 0 &&
          Array.from(Array(rowsPerPage - Math.min(rowsPerPage, state.purchases.length - page * rowsPerPage)).keys()).map((_, index) => (
            <Grid item xs={12} key={`empty-${index}`}>
              <Card className={classes.card} style={{ visibility: 'hidden' }}>
                <CardContent className={classes.cardContent}>
                  {/* Empty Card for spacing */}
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    );
  };

  const renderContent = () => {
    if (state.loading) {
      return (
        <div className={classes.loadingContainer}>
          <ReactLoading type="bubbles" color="#ffbb00" height={100} width={100} />
        </div>
      );
    }

    if (state.error) {
      return (
        <Typography variant="h6" className={classes.noData}>
          An error has occurred. Please try again later.
        </Typography>
      );
    }

    if (state.purchases.length === 0) {
      return (
        <Typography variant="h6" className={classes.noData}>
          No transactions found.
        </Typography>
      );
    }

    return isMobile ? renderCards() : renderTable();
  };

  return (
    <div className={classes.root}>
      <Container className={classes.pageContainer}>
        <Typography variant="h4" className={classes.title}>
          <HistoryIcon fontSize="large" /> Purchase History
        </Typography>
        {renderContent()}
      </Container>
      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PurchaseHistory;
