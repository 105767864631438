import React, { useState } from 'react';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

var config = require('../Config/Config');

const useStyles = makeStyles((theme) => ({
  changePasswordContainer: {
    maxWidth: '400px',
    margin: '3rem auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: '1.5rem',
    borderRadius: '8px',
    boxSizing: 'border-box',
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  changeButton: {
    marginTop: 20,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  error: {
    color: 'red',
    marginTop: '1rem',
    textAlign: 'center',
  },
  success: {
    color: 'green',
    marginTop: '1rem',
    textAlign: 'center',
  },
}));


const ChangePassword = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupSeverity, setPopupSeverity] = useState("success");

  const displayPopup = (severity, message) => {
    setPopupSeverity(severity);
    setPopupMessage(message);
    setPopupOpen(true);
  };

  const postChangePassword = async () => {
    setLoading(true);

    if (!config.isValidPassword(newPassword)) 
    {
      displayPopup("error", `New password is incorrect. 
      It must be between ${config.MINPASSLENGTH + 1} and ${config.MAXPASSLENGTH - 1} 
      characters long and not contain any special characters.`);
      setLoading(false);
      return;
    }

    if (newPassword !== confirmNewPassword) 
    {
      displayPopup("error", "Passwords do not match.");
      setLoading(false);
      return;
    }

    try 
    {
      const response = await fetch('/api/user/changepw', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ newPassword, oldPassword }),
      });

      const data = await response.json();
      if (data.resultCode !== config.resultCodeTable.SUCCESS) 
      {
        displayPopup("error", data.results.error);
      } 
      else 
      {
        displayPopup("success", data.results.message);
      }
    } 
    catch (error) 
    {
      displayPopup("error", error.message || "An error occurred. Please try again.");
    } 
    finally 
    {
      setLoading(false);
    }
  };

  return (
    <>
      {config.isAllowed(props, config.accountLevelCodeTable.user) && (
        <div className={classes.changePasswordContainer}>
          
          <TextField
            className={classes.textField}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            label="Old Password"
            variant="outlined"
            type="password"
            placeholder="Enter your password"
            margin="normal"
          />

          <TextField
            className={classes.textField}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            label="New Password"
            variant="outlined"
            type="password"
            placeholder="Enter a valid password"
            margin="normal"
          />

          <TextField
            className={classes.textField}
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            label="Confirm New Password"
            variant="outlined"
            type="password"
            placeholder="Confirm new password"
            margin="normal"
          />

          <Button
            variant="contained"
            color="primary"
            className={classes.changeButton}
            disabled={loading || oldPassword === "" || newPassword === "" || confirmNewPassword === ""}
            onClick={postChangePassword}
          >
            Change
            {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
          </Button>
        </div>
      )}
      <Snackbar 
        open={popupOpen} 
        autoHideDuration={3000} 
        onClose={() => setPopupOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
        <Alert onClose={() => setPopupOpen(false)} severity={popupSeverity} elevation={6} variant="filled">
            {popupMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ChangePassword;
