import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { FiUserPlus } from 'react-icons/fi';
import SignupModal from './SignupModal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: '110px',
    color: '#000',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'all 0.2s ease',
      color: '#000'
    }
  },
}));

export default function SignupButton({ setAuth }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button startIcon={<FiUserPlus color="#000"/>} color="primary" className = {classes.button} variant="contained" onClick={handleOpen}>
        Signup
      </Button>
      <SignupModal open={open} onClose={handleClose} setAuth={setAuth} />
    </>
  );
}
