import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import Header from '../Header';
import '../../style/User.css';
var config = require('../Config/Config');

function User(props) {

    return (
        <>
            {
                config.isLoggedIn(props) === true && (
                    <div className = "user__container">
                        <Header/>
                        <div className = "user__panel">
                            <Link to='/user/changepw' className = "btn-link">
                                <Button buttonStyle = "btn--outline">Change Password</Button>
                            </Link>
                            <Link to='/user/changemail' className = "btn-link">
                                <Button buttonStyle = "btn--outline">Change Email</Button>
                            </Link>
                            <Link to='/user/changeregion' className = "btn-link">
                                <Button buttonStyle = "btn--outline">Change Default Region</Button>
                            </Link>
                            <Link to='/user/purchasehistory' className = "btn-link">
                                <Button buttonStyle = "btn--outline">View Purchase History</Button>
                            </Link>
                        </div>

                    </div>
                )
            }
        </>
    )
}

export default User
