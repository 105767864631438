import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import {
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';

const config = require('../Config/Config');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Roboto, sans-serif',
    backgroundColor: '#1b1b1b',
    padding: theme.spacing(4, 0),
    minHeight: '100vh',
  },
  pageContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#ffffff',
    padding: theme.spacing(4),
    width: '90%',
    maxWidth: 500,
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    textAlign: 'center',
    border: '2px solid #ffbb00',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#2c2c2c',
  },
  title: {
    color: '#ffbb00',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputField: {
    backgroundColor: '#3a3a3a',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: 400,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiInputLabel-root': {
      color: '#ffffff',
    },
  },
  changeButton: {
    backgroundColor: '#ffbb00',
    color: '#000000',
    padding: theme.spacing(1, 4),
    '&:hover': {
      backgroundColor: '#e0a800',
    },
    marginTop: theme.spacing(2),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20vh',
  },
}));

const ChangeName = (props) => {
  const classes = useStyles();

  const [currentName, setCurrentName] = useState('');
  const [newName, setNewName] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const handleChangeName = async () => {
    if (!currentName.trim() || !newName.trim()) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please provide both current and new character names.');
      setSnackbarOpen(true);
      return;
    }

    if (newName.length < 3 || newName.length > 16) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('New name must be between 3 and 16 characters.');
      setSnackbarOpen(true);
      return;
    }

    if (newName === currentName) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('New name must be different from the current name.');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    const payload = {
      CharacterName: currentName,
      NewName: newName,
    };

    try {
      const response = await fetch('/api/admin/change-name', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok && data.resultCode === 200) {
        setSnackbarSeverity('success');
        setSnackbarMessage(data.results.message || 'Character name changed successfully.');
        setCurrentName('');
        setNewName('');
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(data.results.error || 'Failed to change character name.');
      }
    } catch (error) {
      console.error('Error changing character name:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <>
    {config.isAllowed(props, config.accountLevelCodeTable.moderator) && (
    <div className={classes.root}>
      <Container className={classes.pageContainer}>
        <Typography variant="h4" className={classes.title}>
          <EditIcon fontSize="large" /> Change Character Name
        </Typography>

        <div className={classes.formContainer}>
          <TextField
            variant="outlined"
            placeholder="Enter Current Character Name"
            value={currentName}
            onChange={(e) => setCurrentName(e.target.value)}
            className={classes.inputField}
            fullWidth
            InputProps={{
              style: { color: '#ffffff' },
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleChangeName();
              }
            }}
          />

          <TextField
            variant="outlined"
            placeholder="Enter New Character Name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className={classes.inputField}
            fullWidth
            InputProps={{
              style: { color: '#ffffff' },
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleChangeName();
              }
            }}
          />

          <Button
            variant="contained"
            className={classes.changeButton}
            onClick={handleChangeName}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading type="spin" color="#000000" height={20} width={20} />
            ) : (
              'Change Name'
            )}
          </Button>
        </div>

        {loading && (
          <div className={classes.loadingContainer}>
            <ReactLoading type="bars" color="#ffbb00" height={50} width={50} />
          </div>
        )}
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
    )}
    </>
  );
};

export default ChangeName;
