import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  useTheme,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';
import { regions } from '../Data';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const config = require('../Config/Config');

export default function SignupModal({ open, onClose, setAuth }) {
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [region, setRegion] = useState('');
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleSignup = () => {
    setLoading(true);
    setFormErrors({});
    const data =
    {
        username: username,
        password: password,
        confirmPassword: confirmPassword,
        email: email,
        confirmEmail: confirmEmail,
        region: region
    }
    const validationErrors = config.formValidation(data);
    
    if(!config.isEmptyObject(validationErrors)) 
    {
      setFormErrors(validationErrors);
      setLoading(false);
      return;
    } 

    fetch("/api/user/signup", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
    .then(res => res.json())
    .then(data => 
        {
            if (data.resultCode && data.resultCode === config.resultCodeTable.FAILURE)
            {
                setFormErrors({server: data.results.error});
            }
            else
            {
                setAuth(data);
            }
            setLoading(false);
        }
    )
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      {isMobile && (
        <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            style={{ position: 'absolute', right: 5, top: 5, zIndex: 1000 }}
        >
            <CloseIcon />
        </IconButton>
      )}
      <DialogTitle style={{ color: theme.palette.secondary.main }}>Signup</DialogTitle>
      <DialogContent>
        {Object.keys(formErrors).map((key, index) => {
            return <Alert severity="error" key = {index}>{formErrors[key]}</Alert>
        })}
        <TextField fullWidth margin="normal" variant="outlined" label="Username" value={username} onChange={e => setUsername(e.target.value)} />
        <TextField fullWidth margin="normal" variant="outlined" type="password" label="Password" value={password} onChange={e => setPassword(e.target.value)} />
        <TextField fullWidth margin="normal" variant="outlined" type="password" label="Confirm Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
        <TextField fullWidth margin="normal" variant="outlined" label="Email" value={email} onChange={e => setEmail(e.target.value)} />
        <TextField fullWidth margin="normal" variant="outlined" label="Confirm Email" value={confirmEmail} onChange={e => setConfirmEmail(e.target.value)} />
        
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel>Region</InputLabel>
          <Select 
            value={region} 
            onChange={e => setRegion(e.target.value)}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                },
                getContentAnchorEl: null
            }}>
            {regions.map((region, index) => (
              <MenuItem key={index} value={region.regionID}>{region.regionName}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="contained" color="primary" fullWidth style={{ marginTop: 20, position: 'relative' }} disabled={loading} onClick={handleSignup}>
          Signup
          {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
