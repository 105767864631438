import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Container, Typography, Button, CircularProgress, Tooltip} from "@material-ui/core";
import Header from '../Header';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';

const config = require('../Config/Config');

if (!window.paypal) 
{
  console.error("PayPal SDK was not loaded.");
}

const PayPalButton = window.paypal?.Buttons?.driver("react", { React, ReactDOM });

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    backgroundColor: "#1b1b1b",
    padding: theme.spacing(4, 0),
    minHeight: "100vh",
  },
  pageContainer: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#ffffff",
    padding: theme.spacing(4),
    width: "90%",  // default for mobile
    [theme.breakpoints.up('sm')]: {
      width: "60%",  // for tablets
    },
    [theme.breakpoints.up('md')]: {
      width: "35%",  // for desktop
    },
    textAlign: "center",
    border: "2px solid #ffbb00",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#2c2c2c",
  },
  inputField: {
    width: "100%",
    margin: theme.spacing(4, 0),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#ffbb00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffbb00',
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
  },  
  coinbaseButton: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    color: "#ffffff",
    padding: theme.spacing(1, 3),
    border: "none",
    borderRadius: theme.spacing(0.5),
    cursor: "pointer",
    width: "100%",
    textAlign: "center",
    transition: "background 0.3s ease",
    fontSize: "1.2rem",
    textTransform: "none",
    "&:hover": {
      background: "linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)",
    },
    '&:disabled': {
      background: "#999",
      cursor: "not-allowed",
    },
  },
  paymentButtons: {
    marginTop: theme.spacing(2),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  donationMessages: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  donationProcessing: {
    backgroundColor: "#2196f3",
    color: "#ffffff",
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(0.5),
    width: "100%",
    overflow: "auto",
    textAlign: "center",
  },
  donationSuccess: {
    backgroundColor: "#4caf50",
    color: "#ffffff",
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(0.5),
    width: "100%",
    overflow: "auto",
    textAlign: "center",
  },
  donationTimeout: {
    backgroundColor: "#ff9800",
    color: "#ffffff",
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(0.5),
    width: "100%",
    overflow: "auto",
    textAlign: "center",
  },
  rateInfo: {
    marginTop: theme.spacing(2),
    color: "#ffbb00",
  },
}));

function Donation(props) 
{
  const classes = useStyles();
  
  const RATE = 2000;
  const MIN_DONATION = 1;

  const [donationValue, setDonationValue] = useState(MIN_DONATION);
  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [paymentTimeout, setPaymentTimeout] = useState(false);
  const [invoiceID, setInvoiceID] = useState(null);

  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupSeverity, setPopupSeverity] = useState("error");

  const pollingIntervalRef = useRef(null);
  const timeoutRef = useRef(null);

  const displayPopup = useCallback((severity, message) => {
    setPopupSeverity(severity);
    setPopupMessage(message);
    setPopupOpen(true);
  }, []);

  // Effect to check if payment should be enabled
  useEffect(() => {
    const isEnabled = donationValue >= MIN_DONATION && 
                      !isNaN(donationValue) && 
                      props.userAuth?.accountName && 
                      config.isLoggedIn(props);

    setPaymentEnabled(isEnabled);
  }, [donationValue, props]);

  // Effect to handle polling for transaction status
  useEffect(() => {
    if (paymentProcessing && invoiceID) {
      console.log(`Starting polling for invoice ID: ${invoiceID}`);
      
      // Start polling
      pollingIntervalRef.current = setInterval(() => {
        console.log(`Polling transaction status for ID: ${invoiceID}`);
        axios.get(`/api/ipn/status/${invoiceID}`)
          .then(response => {
            console.log(`Received response for invoice ID ${invoiceID}:`, response.data);
            const status = response.data.results.status;
            if (status === 'success') 
            {
              console.log(`Transaction ${invoiceID} successful.`);
              displayPopup("success", "Your payment was successful. Thank you for your donation!");
              setPaymentSuccessful(true);
              setPaymentProcessing(false);
              clearInterval(pollingIntervalRef.current);
              clearTimeout(timeoutRef.current);
            } else if (status === 'failed') {
              console.warn(`Transaction ${invoiceID} failed.`);
              displayPopup("error", "Your payment was not successful. Please try again.");
              setPaymentProcessing(false);
              clearInterval(pollingIntervalRef.current);
              clearTimeout(timeoutRef.current);
            } else {
              console.log(`Transaction ${invoiceID} status: ${status}`);
            }
          })
          .catch(error => {
            console.error(`Error fetching payment status for transaction ID ${invoiceID}:`, error);
            displayPopup("error", "An error occurred while fetching payment status.");
          });
      }, 5000); // Poll every 5 seconds

      timeoutRef.current = setTimeout(() => {
        console.warn(`Transaction ${invoiceID} timed out.`);
        setPaymentTimeout(true);
        setPaymentProcessing(false);
        clearInterval(pollingIntervalRef.current);
        displayPopup("warning", "Payment is taking longer than expected. You will receive an email confirmation once the payment is completed.");
      }, 120000); // 2 minutes

      return () => {
        console.log(`Clearing polling and timeout for transaction ID: ${invoiceID}`);
        clearInterval(pollingIntervalRef.current);
        clearTimeout(timeoutRef.current);
      };
    }
  }, [paymentProcessing, invoiceID, displayPopup]);

  const handleChangeDonation = useCallback(e => {
    let value = e.target.value;
    console.log(`Donation input changed: ${value}`);
    if (value === '') {
      setDonationValue('');
      return;
    }
  
    value = Number(value);
    if (!isNaN(value)) 
    {
      setDonationValue(value);
    } 
    else 
    {
      console.warn(`Invalid donation value entered: ${value}`);
    }
  }, []);

  const handleBlur = useCallback(() => {
    if (donationValue === '' || isNaN(donationValue) || donationValue < MIN_DONATION) 
    {
      console.warn(`Donation value invalid on blur: ${donationValue}. Resetting to minimum: ${MIN_DONATION}`);
      setDonationValue(MIN_DONATION);
      displayPopup("warning", `Donation amount must be at least ${MIN_DONATION} EUR.`);
    }
  }, [donationValue, displayPopup]);

  const formatNumber = useCallback((number) => {
    const formatter = new Intl.NumberFormat(navigator.language, {
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  }, []);

  const createOrder = useCallback(() => {
    setPaymentTimeout(false);
    setPaymentProcessing(false);
    setPaymentSuccessful(false);
    setInvoiceID(null);
    console.log(`Creating PayPal order for donation value: ${donationValue} EUR`);

    return axios.post('/api/ipn/create-order', {
      donationValue: donationValue,
      currency: 'EUR'
    })
    .then(response => {
      console.log('PayPal order created successfully:', response.data);
      setInvoiceID(response.data.results.invoiceID);
      return response.data.results.orderID;
    })
    .catch(error => {
      console.error("Error creating PayPal order:", error);
      displayPopup("error", "An error occurred while creating the order. Please try again.");
      throw error;
    });
  }, [donationValue, displayPopup]);

  const onApprove = useCallback((data, actions) => {
    setPaymentProcessing(true);
  }, []);

  const onError = useCallback((err) => {
    console.error("PayPal transaction error:", err);
    setPaymentProcessing(false);
    displayPopup("error", "An error occurred during the transaction. Please try again.");
  }, [displayPopup]);

  const onCancel = useCallback((data) => {
    console.log("PayPal transaction cancelled:", data);
    setPaymentProcessing(false);
    displayPopup("info", "Transaction cancelled.");
  }, [displayPopup]);

  const handleCoinbaseDonation = useCallback(() => {
    setInvoiceID(null);
    console.log(`Initiating Coinbase donation for value: ${donationValue} EUR`);
    setPaymentProcessing(true);
    fetch("/api/ipn/createcbcharge", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        donationValue: donationValue,
      }),
    })
    .then(response => {
      console.log('Received response from Coinbase charge creation:', response);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      return response.json();
    })
    .then(data => {
      setPaymentProcessing(false);
      const result = data.results.data.data;
      if (result && result.hosted_url) {
        console.log(`Redirecting to Coinbase hosted URL: ${result.hosted_url}`);
        window.open(result.hosted_url, '_blank');
      }
      else {
        console.error("Invalid Coinbase response structure:", data);
        displayPopup("error", "An error occurred while processing your donation. Please try again.");
      }
    })
    .catch(error => {
      console.error("Error during Coinbase donation:", error);
      setPaymentProcessing(false);
      displayPopup("error", error.message || "An error occurred while processing your donation. Please try again.");
    });
  }, [donationValue, displayPopup]);

  return (
    <Container className={classes.root}>
      <Header />
      <div className={classes.pageContainer}>
        <Typography variant="h4" component="h1" gutterBottom>
          Support DNOrigins
        </Typography>
        <Typography variant="body1">
          Rates: 1 EUR = {formatNumber(RATE)} OC
        </Typography>
        <Tooltip title={`Minimum donation is ${MIN_DONATION} EUR`}>
          <TextField
            label={`Donation Amount (€) Min: ${MIN_DONATION}€`}
            type="number"
            variant="outlined"
            value={donationValue}
            onChange={handleChangeDonation}
            onBlur={handleBlur}
            className={classes.inputField}
            disabled={!config.isLoggedIn(props)}
            InputProps={{ inputProps: { min: MIN_DONATION, step: 1 } }}
            helperText={donationValue < MIN_DONATION ? `Minimum donation is ${MIN_DONATION} EUR` : ''}
            error={donationValue < MIN_DONATION}
            aria-label="Donation Amount in EUR"
          />
        </Tooltip>
        <Typography variant="h6" className={classes.rateInfo}>
          OC to receive: {formatNumber(Math.floor(donationValue * RATE))}
        </Typography>
        <div className={paymentEnabled ? classes.paymentButtons : classes.paymentButtons}>
          {PayPalButton ? (
            <PayPalButton 
              createOrder={createOrder} 
              onApprove={onApprove} 
              onError={onError}
              onCancel={onCancel}
              disabled={!paymentEnabled || paymentProcessing}
            />
          ) : (
            <Alert severity="error">PayPal Buttons not available.</Alert>
          )}
          <Button
            variant="contained"
            className={classes.coinbaseButton}
            onClick={handleCoinbaseDonation}
            disabled={!paymentEnabled || paymentProcessing}
            aria-label="Donate with Crypto"
          >
            Donate with Crypto
          </Button>
        </div>
        <div className={classes.donationMessages}>
          {!config.isLoggedIn(props) && <Alert severity="error">You must log in to be able to donate.</Alert>}
          {paymentProcessing && (
            <div className={classes.donationProcessing}>
              <CircularProgress color="inherit" />
              <Typography variant="h5" gutterBottom>
                Donation Processing
              </Typography>
              <Typography variant="body1" paragraph>
                Thank you for your donation! Your payment of {donationValue} EUR is being processed.
              </Typography>
              <Typography variant="body1" paragraph>
                You will receive a confirmation email once the payment is completed.
              </Typography>
              <Typography variant="body1">
                If you have any questions, please create a support ticket in our Discord's #support channel.
              </Typography>
            </div>
          )}
          {paymentSuccessful && (
            <div className={classes.donationSuccess}>
              <Typography variant="h5" gutterBottom>
                Donation Successful!
              </Typography>
              <Typography variant="body1" paragraph>
                Thank you for your donation of {formatNumber(Math.floor(donationValue * RATE))} OC.
              </Typography>
              <Typography variant="body1" paragraph>
                Your payment has been successfully processed.
              </Typography>
              <Typography variant="body1">
                If you have any questions or concerns, please contact our support team in our Discord's #support channel.
              </Typography>
            </div>
          )}
          {paymentTimeout && (
            <div className={classes.donationTimeout}>
              <Typography variant="h5" gutterBottom>
                Processing Taking Longer Than Expected
              </Typography>
              <Typography variant="body1" paragraph>
                Your donation of {donationValue} EUR is taking longer than usual to process.
              </Typography>
              <Typography variant="body1" paragraph>
                You will receive a confirmation email once the payment is completed.
              </Typography>
              <Typography variant="body1">
                If you have any questions, please create a support ticket in our Discord's #support channel.
              </Typography>
            </div>
          )}
        </div>
      </div>
      {/* Snackbar for Notifications */}
      <Snackbar 
        open={popupOpen} 
        autoHideDuration={5000} 
        onClose={() => setPopupOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setPopupOpen(false)} severity={popupSeverity} elevation={6} variant="filled">
          {popupMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Donation;
