import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import moment from 'moment';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Snackbar,
  useMediaQuery,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    backgroundColor: '#2c2c2c',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
    '& th': {
      backgroundColor: '#3a3a3a',
      color: '#ffbb00',
    },
    '& td': {
      backgroundColor: '#3a3a3a',
      color: '#ffffff',
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(255, 187, 0, 0.1)',
    },
  },
  noData: {
    color: '#ffffff',
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
  card: {
    backgroundColor: '#2c2c2c',
    color: '#ffffff',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  cardField: {
    marginBottom: theme.spacing(1),
  },
  tableSortLabel: {
    color: '#ffbb00',
    '&:hover': {
      color: '#ffffff',
    },
    '&.MuiTableSortLabel-active': {
      color: '#ffffff',
    },
    '& .MuiTableSortLabel-icon': {
      color: '#ffffff',
    },
  },
}));

const MailsSentTable = ({ characterId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [state, setState] = useState({
    loading: true,
    error: null,
    mailsSent: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // Placeholder data. Replace with actual API call.

  useEffect(() => {
    const fetchMailsSent = async () => {
      const mockMailsSent = [
        {
          id: 1,
          date: '2024-04-05T16:00:00Z',
          recipient: 'PlayerOne',
          subject: 'Greetings!',
          status: 'Delivered',
        },
        {
          id: 2,
          date: '2024-04-06T12:30:00Z',
          recipient: 'PlayerTwo',
          subject: 'Quest Help',
          status: 'Read',
        },
        // Add more mock mails sent as needed
      ];
    
      try {
        // Simulate API call with timeout
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // Replace the following line with actual API response
        const data = { resultCode: 'SUCCESS', results: { rows: mockMailsSent } };

        if (data.resultCode !== 'SUCCESS') {
          setState({ loading: false, error: true, mailsSent: [] });
          setSnackbarSeverity('error');
          setSnackbarMessage('Failed to load mails sent.');
          setSnackbarOpen(true);
        } else {
          setState({
            loading: false,
            error: false,
            mailsSent: data.results.rows.reverse(),
          });
        }
      } catch (error) {
        console.error('Error fetching mails sent:', error);
        setSnackbarSeverity('error');
        setSnackbarMessage('An error has occurred. Please try again later.');
        setSnackbarOpen(true);
        setState({ loading: false, error: true, mailsSent: [] });
      }
    };

    fetchMailsSent();
  }, [characterId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orderResult = comparator(a[0], b[0]);
      if (orderResult !== 0) return orderResult;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    let aValue, bValue;
    switch (orderBy) {
      case 'date':
        aValue = new Date(a.date);
        bValue = new Date(b.date);
        break;
      case 'recipient':
        aValue = a.recipient.toLowerCase();
        bValue = b.recipient.toLowerCase();
        break;
      case 'subject':
        aValue = a.subject.toLowerCase();
        bValue = b.subject.toLowerCase();
        break;
      case 'status':
        aValue = a.status.toLowerCase();
        bValue = b.status.toLowerCase();
        break;
      default:
        aValue = new Date(a.date);
        bValue = new Date(b.date);
    }

    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const renderTable = () => {
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, state.mailsSent.length - page * rowsPerPage);

    return (
      <>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table} aria-label="mails sent table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'date'}
                    direction={orderBy === 'date' ? order : 'asc'}
                    onClick={() => handleRequestSort('date')}
                    className={classes.tableSortLabel}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'recipient'}
                    direction={orderBy === 'recipient' ? order : 'asc'}
                    onClick={() => handleRequestSort('recipient')}
                    className={classes.tableSortLabel}
                  >
                    Recipient
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'subject'}
                    direction={orderBy === 'subject' ? order : 'asc'}
                    onClick={() => handleRequestSort('subject')}
                    className={classes.tableSortLabel}
                  >
                    Subject
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={orderBy === 'status' ? order : 'asc'}
                    onClick={() => handleRequestSort('status')}
                    className={classes.tableSortLabel}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(state.mailsSent, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((mail, index) => {
                  const mailDate = moment(mail.date).format('DD-MM-YYYY HH:mm');
                  return (
                    <TableRow key={index} className={classes.tableRow} hover>
                      <TableCell>{mailDate}</TableCell>
                      <TableCell>{mail.recipient}</TableCell>
                      <TableCell>{mail.subject}</TableCell>
                      <TableCell>{mail.status}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={state.mailsSent.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Rows per page:"
          style={{ color: '#ffffff' }}
        />
      </>
    );
  };

  const renderCards = () => {
    return (
      <Grid container spacing={2}>
        {state.mailsSent.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((mail, index) => {
          const mailDate = moment(mail.date).format('DD-MM-YYYY HH:mm');
          return (
            <Grid item xs={12} key={index}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="body1" className={classes.cardField}>
                    <strong>Date:</strong> {mailDate}
                  </Typography>
                  <Typography variant="body1" className={classes.cardField}>
                    <strong>Recipient:</strong> {mail.recipient}
                  </Typography>
                  <Typography variant="body1" className={classes.cardField}>
                    <strong>Subject:</strong> {mail.subject}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Status:</strong> {mail.status}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
        {rowsPerPage - Math.min(rowsPerPage, state.mailsSent.length - page * rowsPerPage) > 0 &&
          Array.from(
            Array(rowsPerPage - Math.min(rowsPerPage, state.mailsSent.length - page * rowsPerPage)).keys()
          ).map((_, index) => (
            <Grid item xs={12} key={`empty-${index}`}>
              <Card className={classes.card} style={{ visibility: 'hidden' }}>
                <CardContent className={classes.cardContent}>
                  {/* Empty Card for spacing */}
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    );
  };

  const renderContent = () => {
    if (state.loading) {
      return (
        <div className={classes.loadingContainer}>
          <ReactLoading type="bubbles" color="#ffbb00" height={100} width={100} />
        </div>
      );
    }

    if (state.error) {
      return (
        <Typography variant="h6" className={classes.noData}>
          An error has occurred. Please try again later.
        </Typography>
      );
    }

    if (state.mailsSent.length === 0) {
      return (
        <Typography variant="h6" className={classes.noData}>
          No mails sent found.
        </Typography>
      );
    }

    return isMobile ? renderCards() : renderTable();
  };

  return (
    <>
      {renderContent()}
      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MailsSentTable;
