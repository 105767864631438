import React, { useState } from 'react';
import { Button, TextField, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

var config = require('../Config/Config');

const useStyles = makeStyles((theme) => ({
  changeEmailContainer: {
    maxWidth: '400px',
    margin: '3rem auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: '1.5rem',
    borderRadius: '8px',
    boxSizing: 'border-box',
  },
  currentEmail: {
    color: theme.palette.grey[600],
    fontSize: '1.1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  changeButton: {
    marginTop: 20,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  textField: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  error: {
    color: 'red',
    marginTop: '1rem',
    textAlign: 'center',
  },
  success: {
    color: 'green',
    marginTop: '1rem',
    textAlign: 'center',
  },
}));


export default function ChangeEmail(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [confirmNewEmail, setConfirmNewEmail] = useState("");

  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupSeverity, setPopupSeverity] = useState("success");

  const displayPopup = (severity, message) => {
    setPopupSeverity(severity);
    setPopupMessage(message);
    setPopupOpen(true);
  };

  const postChangeEmail = () => {
    setLoading(true);

    if(newEmail === props.userAuth.email)
    {
      displayPopup("error", "New email cannot be the same as the current email");
      setLoading(false);
      return;
    }

    if (!config.isValidEmail(newEmail)) 
    {
      displayPopup("error", `${newEmail} is not a valid email`);
      setLoading(false);
      return;
    }

    if (newEmail !== confirmNewEmail) 
    {
      displayPopup("error", "Emails do not match");
      setLoading(false);
      return;
    }

    fetch("/api/user/changemail", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            newEmail: newEmail,
        }),
    })
    .then((res) => res.json())
    .then((data) => {
        if (data.resultCode !== config.resultCodeTable.SUCCESS) 
        {
          displayPopup("error", data.results.error);
        } 
        else 
        {
          props.setAuth({ ...props.userAuth, email: newEmail });
          displayPopup("success", data.results.message);
        }
    })
    .catch(error => {
      displayPopup("error", error.message || "An error occurred. Please try again.");
    })
    .finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      {config.isAllowed(props, config.accountLevelCodeTable.user) && (
        <div className={classes.changeEmailContainer}>
          <Typography className={classes.currentEmail}>Current Email: {props.userAuth.email}</Typography>
          
          <TextField
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            label="New Email"
            variant="outlined"
            type="email"
            placeholder="Enter a valid email"
            className={classes.textField}
            margin="normal"
          />

          <TextField
            value={confirmNewEmail}
            onChange={(e) => setConfirmNewEmail(e.target.value)}
            label="Confirm New Email"
            variant="outlined"
            type="email"
            placeholder="Confirm new email"
            className={classes.textField}
            margin="normal"
          />

          <Button
            variant="contained"
            color="primary"
            className={classes.changeButton}
            disabled={loading || newEmail === "" || confirmNewEmail === ""}
            onClick={postChangeEmail}
          >
            Change
            {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
          </Button>
        </div>
      )}
      <Snackbar 
        open={popupOpen} 
        autoHideDuration={3000} 
        onClose={() => setPopupOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
        <Alert onClose={() => setPopupOpen(false)} severity={popupSeverity} elevation={6} variant="filled">
            {popupMessage}
        </Alert>
      </Snackbar>
    </>
  );
}