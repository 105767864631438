import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import {
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Roboto, sans-serif',
    backgroundColor: '#1b1b1b',
    padding: theme.spacing(4, 0),
    minHeight: '100vh',
  },
  pageContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#ffffff',
    padding: theme.spacing(4),
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    textAlign: 'center',
    border: '2px solid #ffbb00',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#2c2c2c',
  },
  title: {
    color: '#ffbb00',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  tabsRoot: {
    backgroundColor: '#3a3a3a',
    borderRadius: theme.spacing(1),
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tabPanel: {
    width: '100%',
    padding: theme.spacing(2),
  },
  inputField: {
    backgroundColor: '#3a3a3a',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiInputLabel-root': {
      color: '#ffffff',
    },
  },
  banButton: {
    backgroundColor: '#ff4d4d',
    color: '#ffffff',
    padding: theme.spacing(1, 4),
    '&:hover': {
      backgroundColor: '#e60000',
    },
    marginTop: theme.spacing(2),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20vh',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ban-tabpanel-${index}`}
      aria-labelledby={`ban-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const BanAccount = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [tabValue, setTabValue] = useState(0);
  const [identifierValue, setIdentifierValue] = useState('');
  const [banReason, setBanReason] = useState('');
  const [banDuration, setBanDuration] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setIdentifierValue('');
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const handleBanSubmit = async () => {
    if (!identifierValue.trim()) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please enter the identifier value.');
      setSnackbarOpen(true);
      return;
    }

    if (!banReason.trim()) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please provide a reason for the ban.');
      setSnackbarOpen(true);
      return;
    }

    if (!banDuration) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please select the ban duration.');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    const payload = {
      CharacterID: tabValue === 1 ? identifierValue : null,
      CharacterName: tabValue === 0 ? identifierValue : null,
      AccountID: tabValue === 3 ? identifierValue : null,
      AccountName: tabValue === 2 ? identifierValue : null,
      BanReason: banReason,
      BanDuration: Number(banDuration),
    };

    try {
      const response = await fetch('/api/admin/ban', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      console.log('Ban Response:', data);

      if (data.resultCode !== 200) {
        setSnackbarSeverity('error');
        setSnackbarMessage(data.message || 'Failed to ban the account.');
      } else {
        setSnackbarSeverity('success');
        setSnackbarMessage('Account banned successfully.');

        setIdentifierValue('');
        setBanReason('');
        setBanDuration('');
      }
    } catch (error) {
      console.error('Error banning account:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const banPresets = [
    { label: '1 Day', hours: 24 },
    { label: '3 Days', hours: 72 },
    { label: '1 Week', hours: 168 },
    { label: '2 Weeks', hours: 336 },
    { label: '1 Month', hours: 720 },
    { label: '3 Months', hours: 2160 },
    { label: '1 Year', hours: 8760 },
    { label: '5 Years', hours: 43800 },
  ];

  return (
    <div className={classes.root}>
      <Container className={classes.pageContainer}>
        <Typography variant="h4" className={classes.title}>
          <BlockIcon fontSize="large" /> Ban Account
        </Typography>

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabsRoot}
          variant={isMobile ? 'scrollable' : 'standard'}
          scrollButtons="auto"
          centered={!isMobile}
        >
          <Tab label="Character Name" />
          <Tab label="Character ID" />
          <Tab label="Account Name" />
          <Tab label="Account ID" />
        </Tabs>

        <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
          <TextField
            variant="outlined"
            placeholder="Enter Character Name"
            value={identifierValue}
            onChange={(e) => setIdentifierValue(e.target.value)}
            className={classes.inputField}
            fullWidth
          />
        </TabPanel>

        <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
          <TextField
            variant="outlined"
            placeholder="Enter Character ID"
            value={identifierValue}
            onChange={(e) => setIdentifierValue(e.target.value)}
            className={classes.inputField}
            fullWidth
          />
        </TabPanel>

        <TabPanel value={tabValue} index={2} className={classes.tabPanel}>
          <TextField
            variant="outlined"
            placeholder="Enter Account Name"
            value={identifierValue}
            onChange={(e) => setIdentifierValue(e.target.value)}
            className={classes.inputField}
            fullWidth
          />
        </TabPanel>

        <TabPanel value={tabValue} index={3} className={classes.tabPanel}>
          <TextField
            variant="outlined"
            placeholder="Enter Account ID"
            value={identifierValue}
            onChange={(e) => setIdentifierValue(e.target.value)}
            className={classes.inputField}
            fullWidth
          />
        </TabPanel>

        <TextField
          variant="outlined"
          placeholder="Ban Reason"
          value={banReason}
          onChange={(e) => setBanReason(e.target.value)}
          className={classes.inputField}
          fullWidth
          multiline
          minRows={4}
        />

        <FormControl variant="outlined" className={classes.inputField} fullWidth>
          <InputLabel id="ban-duration-label" style={{ color: '#ffffff' }}>
            Ban Duration
          </InputLabel>
          <Select
            labelId="ban-duration-label"
            value={banDuration}
            onChange={(e) => setBanDuration(e.target.value)}
            label="Ban Duration"
            style={{ color: '#ffffff' }}
          >
            {banPresets.map((preset, index) => (
              <MenuItem key={index} value={preset.hours}>
                {preset.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          className={classes.banButton}
          onClick={handleBanSubmit}
          disabled={loading}
        >
          {loading ? (
            <ReactLoading type="spin" color="#ffffff" height={20} width={20} />
          ) : (
            'Ban Account'
          )}
        </Button>

        {loading && (
          <div className={classes.loadingContainer}>
            <ReactLoading type="bars" color="#ff4d4d" height={50} width={50} />
          </div>
        )}
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BanAccount;
