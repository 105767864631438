import React from 'react';
import { TextField, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    input: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const RewardItem = ({ item, index, handleChangeItem, handleRemoveItem }) => {
    const classes = useStyles();

    return (
        <div key={index}>
            <TextField className={classes.input} value={item.itemid} onChange={e => handleChangeItem(index, 'itemid', Number(e.target.value))} label="Item ID" variant="outlined" />
            <TextField className={classes.input} value={item.productflag} onChange={e => handleChangeItem(index, 'productflag', Number(e.target.value))} label="Product Flag" variant="outlined" />
            <TextField className={classes.input} value={item.count} onChange={e => handleChangeItem(index, 'count', Number(e.target.value))} label="Count" variant="outlined" />
            <TextField className={classes.input} value={item.durability} onChange={e => handleChangeItem(index, 'durability', Number(e.target.value))} label="Durability" variant="outlined" />
            <TextField className={classes.input} value={item.randomseed} onChange={e => handleChangeItem(index, 'randomseed', Number(e.target.value))} label="Random Seed" variant="outlined" />
            <TextField className={classes.input} value={item.enhancelevel} onChange={e => handleChangeItem(index, 'enhancelevel', Number(e.target.value))} label="Enhance Level" variant="outlined" />
            <TextField className={classes.input} value={item.potential} onChange={e => handleChangeItem(index, 'potential', Number(e.target.value))} label="Potential" variant="outlined" />
            <TextField className={classes.input} value={item.soulbound} onChange={e => handleChangeItem(index, 'soulbound', Number(e.target.value))} label="Soulbound" variant="outlined" />
            <TextField className={classes.input} value={item.seals} onChange={e => handleChangeItem(index, 'seals', Number(e.target.value))} label="Seals" variant="outlined" />
            <TextField className={classes.input} value={item.option} onChange={e => handleChangeItem(index, 'option', Number(e.target.value))} label="Option" variant="outlined" />
            <TextField className={classes.input} value={item.lifespan} onChange={e => handleChangeItem(index, 'lifespan', Number(e.target.value))} label="Lifespan" variant="outlined" />
            <TextField className={classes.input} value={item.eternity} onChange={e => handleChangeItem(index, 'eternity', Number(e.target.value))} label="Eternity" variant="outlined" />
            <TextField className={classes.input} value={item.dragonjeweltype} onChange={e => handleChangeItem(index, 'dragonjeweltype', Number(e.target.value))} label="Jewel Type" variant="outlined" />
            <Button className={classes.button} onClick={() => handleRemoveItem(index)}>Remove Item</Button>
        </div>
    );
};

export default RewardItem;
