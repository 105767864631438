import React, {useState, useEffect} from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import Download from './components/Download/Download';
import Footer from './components/Footer';
import Donation from './components/Donation/Donation';
import ResetPass from './components/ResetPass';
import Admin from './components/Admin/Admin';
import AccountIdentification from './components/Admin/AccountIdentification';
import CharacterIdentification from './components/Admin/CharacterIdentification';
import JobPerformance from './components/Admin/JobPerformance';
import SendCash from './components/Admin/SendCash';
import SendMails from './components/Admin/SendMails'
import ChangeName from './components/Admin/ChangeName';
import BanAccount from './components/Admin/BanAccount';
import EventRewards from './components/Admin/EventRewards';
import SendRewards from './components/Admin/SendRewards';
import User from './components/User/User';
import ChangePassword from './components/User/ChangePassword';
import ChangeEmail from './components/User/ChangeEmail';
import ChangeRegion from './components/User/ChangeRegion';
import PurchaseHistory from './components/User/PurchaseHistory';
import PageNotFound from './components/PageNotFound';
import Tickets from './components/Ticket/Tickets';
import theme from './components/Theme';
import { ThemeProvider } from '@material-ui/core/styles';
import ScrollToTop from './components/ScrollToTop';
import Launcher from './components/Launcher/Launcher';

var config = require('./components/Config/Config');

function App() {
  const [userAuth, setAuth] = useState({});
  const showLayout = !window.location.pathname.includes("/launch");

  const fetchUserState = () => {
    fetch("/api/user", {
      method: "GET",
      headers: {
          'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      if (data.resultCode === config.resultCodeTable.FAILURE) 
      {
        setAuth({});
      }
      else 
      {
        setAuth(data);
      }
    }
    )
  };

  useEffect(() => {
    fetchUserState();
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
      <ScrollToTop />
      <div className = "app__container"> 
      {showLayout && <Navbar userAuth = {userAuth} setAuth = {setAuth}/> }
      <Switch>
        <Route exact path = '/' component = {Home}/>
        <Route exact path = "/download" component = {Download}/>
        <Route path='/news' component={() => { window.location.href = 'https://dnorigins.com/news'; return null;}}/>
        <Route path='/guide' component={() => { window.location.href = 'https://dnorigins.com/news/guide'; return null;}}/>
        
        <Route path='/PatchConfigList.xml' component={() => { window.location.href = 'https://legacy.dnorigins.com/PatchConfigList.xml'; return null;}}/>
        <Route path='/PatchConfigList_test.xml' component={() => { window.location.href = 'https://legacy.dnorigins.com/PatchConfigList_test.xml'; return null;}}/>
        <Route path='/launcher' component={() => { window.location.href = 'https://legacy.dnorigins.com/launcher'; return null;}}/>
        <Route path='/launch' render = {(props) => <Launcher {...props}/>}/>
        <Route exact path = "/donate" render = {(props) => <Donation userAuth = {userAuth} {...props}/>}/>
        <Route exact path = "/support" render = {(props) => <Tickets userAuth = {userAuth} {...props}/>}/>

        <Route exact path = "/resetpassconfirm" render = {(props) => <ResetPass {...props}/>}/>
        {
          config.isLoggedInApp(userAuth) && (
            <>
              {
                config.isAllowedApp(userAuth, config.accountLevelCodeTable.moderator) && (
                  <>
                    <Route exact path = "/admin" render = {(props) => <Admin userAuth = {userAuth} {...props}/>}/>
                    <Route exact path = "/admin/accid" render = {(props) => <AccountIdentification userAuth = {userAuth} {...props}/>}/>
                    <Route exact path = "/admin/charid" render = {(props) => <CharacterIdentification userAuth = {userAuth} {...props}/>}/>
                    <Route exact path = "/admin/jobperformance" render = {(props) => <JobPerformance userAuth = {userAuth} {...props}/>}/>
                    <Route exact path = "/admin/changename" render = {(props) => <ChangeName userAuth = {userAuth} {...props}/>}/>
                    <Route exact path = "/admin/sendcash" render = {(props) => <SendCash userAuth = {userAuth} {...props}/>}/>
                    <Route exact path = "/admin/sendmails" render = {(props) => <SendMails userAuth = {userAuth} {...props}/>}/>
                    <Route exact path = "/admin/ban" render = {(props) => <BanAccount userAuth = {userAuth} {...props}/>}/>
                    <Route exact path = "/admin/rewards" render = {(props) => <EventRewards userAuth = {userAuth} {...props}/>}/>
                    <Route exact path = "/admin/sendrewards" render = {(props) => <SendRewards userAuth = {userAuth} {...props}/>}/>
                  </>
                )
              }
              
              <Route exact path = "/user" render = {(props) => <User userAuth = {userAuth} {...props}/>}/>
              <Route exact path = "/user/changepw" render = {(props) => <ChangePassword userAuth = {userAuth} {...props}/>}/>
              <Route exact path = "/user/changemail" render = {(props) => <ChangeEmail userAuth = {userAuth} setAuth = {setAuth} {...props}/>}/>
              <Route exact path = "/user/changeregion" render = {(props) => <ChangeRegion userAuth = {userAuth} setAuth = {setAuth} {...props}/>}/>
              <Route exact path = "/user/purchasehistory" render = {(props) => <PurchaseHistory userAuth = {userAuth} {...props}/>}/>
            </>
          )
        }

        <Route component = {PageNotFound} />
      </Switch>
      </div>
      {showLayout && <Footer/>}
      </ThemeProvider>
    </Router>
  );
}

export default App;
