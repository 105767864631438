import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardMedia, Typography, Button } from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { URLs } from './Data';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) =>
  createStyles({
    newsSection: {
      padding: '2rem 10%',
      textAlign: 'center',
    },
    newsCard: {
      maxWidth: '300px',
      margin: '0 5px',
      backgroundColor: theme.palette.background.paper,
      transition: 'transform 0.3s',
      overflow: 'hidden',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    },
    media: {
      height: 180,
    },
    newsButton: {
        marginTop: 'auto', 
        marginBottom: '1rem',
    },
    sectionTitle: {
      color: theme.palette.primary.main,
      marginBottom: '2rem',
    },    
    slickList: {
        textAlign: 'center',
    },
    slickSlide: {
        display: 'inline-block',
        width: 'auto',
    }, 
    cardContent: {
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    moreNewsText: {
        color: '#808080',
        fontSize: '0.8rem',
        marginTop: '1rem',
        textAlign: 'center',
    },
    linkStyle: {
        color: '#b0b0b0',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
  })
);

function NewsSlider() {
    const classes = useStyles();
    const [newsData, setNewsData] = useState([]);
    const [slidesToShow, setSlidesToShow] = useState(window.innerWidth <= 768 ? 1 : 3);

    useEffect(() => {
        const handleResize = () => {
            setSlidesToShow(window.innerWidth <= 768 ? 1 : 3);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        fetch('https://dnorigins.com/news/ghost/api/v4/content/posts/?key=5dbd62f5253ca5fe67ae700a8f&limit=5')
            .then(response => {
                if (!response.ok) 
                {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setNewsData(data.posts);
            })
            .catch(error => console.error("Error fetching news:", error));
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: false,
        initialSlide: 0,
    };

    return (
    <div className={classes.newsSection}>
        <Typography variant="h4" className={classes.sectionTitle}>Latest News</Typography>
        <Slider {...settings} className={classes.slickList}>
        {newsData.map((news, index) => (
            <Card className={classes.newsCard} key={index}>
                <CardMedia
                className={classes.media}
                image={news.feature_image}
                title={news.title}
                />
                <CardContent className={classes.cardContent}>
                <Typography variant="h6" component="h2">
                    {news.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {news.excerpt}
                </Typography>
                <Button 
                    className={classes.newsButton}
                    variant="contained" 
                    color="secondary" 
                    href={news.url} 
                    target="_blank">
                    Read More
                </Button>
                </CardContent>
            </Card>
        ))}
        </Slider>
        <Typography className={classes.moreNewsText}>
            For more updates, check out our #announcements channel on{" "}
            <Link to={URLs.discord} target="_blank" rel="noopener noreferrer" className={classes.linkStyle}>
                DNOrigins Discord
            </Link>.
        </Typography>
    </div>
    );
}

export default NewsSlider;
