import React, { useState } from 'react'
import { Button } from '../Button';
import {jobCodes} from '../Data';
import '../../style/Admin.css';
import ReactLoading from 'react-loading';

var config = require('../Config/Config');

export default function AccountIdentification(props) {
    const [accIDFailed, setAccIDFailed] = useState(false);
    const [accData, setAccData] = useState({});
    const [loading, setLoading] = useState(false);
  
    function FetchAccount()
    {
        var accountName = document.getElementById('accountName').value;
        setLoading(true);

        fetch("/api/admin/accid?accountName=" + accountName, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(data => 
            {
                if (data.resultCode !== config.resultCodeTable.SUCCESS)
                {
                    setAccIDFailed(true);
                }
                else
                {
                    setAccIDFailed(false);
                    setAccData(data);
                    console.log(data);
                }
                setLoading(false);
            }
        )
    };
    function formatDate(date)
    {
        return date.replace("T", " ").replace("Z", " ");
    }  

    return (
        <div className = "accountsearch_page">
        {
            config.isAllowed(props, config.accountLevelCodeTable.moderator) && (
                <div className = "account__identif">
                    {
                        config.isEmptyObject(accData) ? (
                            <>
                                <h1>Account Name</h1>
                                <input type = "text" id = "accountName" placeholder = "Enter a valid account name. Capitalization matters!"/>
                                <Button onClick = {FetchAccount}>Search</Button>
                                { accIDFailed ? (<><br/><p>Account does not exist.</p></>) : (<br/>)}
                                {loading ? <ReactLoading type = {"bubbles"} height = {"128px"} width = {"128px"}/> : null}
                            </>
                        ) : (
                            <>
                                <h3>RESULTS</h3>
                                <table>
                                <thead>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Level
                                    </th>
                                    <th>
                                        Character ID
                                    </th>
                                    <th>
                                        Class
                                    </th>
                                    <th>
                                        Account ID
                                    </th>
                                    <th>
                                        AccoutLevelCode
                                    </th>
                                    <th>
                                        Inventory Gold
                                    </th>
                                    <th>
                                        Storage Gold
                                    </th>
                                    <th>
                                        Create Date
                                    </th>                                 
                                </thead>
                                <tbody>
                                    {accData.results.rows.map((character, index) => {
                                        return(
                                            <tr key = {index}>
                                                <td>
                                                    {character[0].value}
                                                </td>
                                                <td>
                                                    {character[1].value}
                                                </td>
                                                <td>
                                                    {character[2].value}
                                                </td>
                                                <td>
                                                    {jobCodes[character[3].value]}
                                                </td>
                                                <td>
                                                    {character[4].value}
                                                </td>
                                                <td>
                                                    {character[5].value}
                                                </td>
                                                <td>
                                                    {parseInt(character[6].value / 10000)}
                                                </td>
                                                <td>
                                                    {parseInt(character[7].value / 10000)}
                                                </td>
                                                <td>
                                                    {formatDate(character[8].value)}
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                                </table>
                            </>
                        )
                    }
                
                </div>
            )
        }
      </div>
    );
  }
