import React, { useState } from 'react'
import { Button } from '../Button';
import '../../style/Admin.css';
var config = require('../Config/Config');

export default function SendCash(props) {
    const [sendCashFailed, setSendCashFailed] = useState(false);
    const [sendCashSuccess, setSendCashSuccess] = useState({});

    function PostSendCash()
    {
        var accountName = document.getElementById('accountName').value;
        var cashAmount = document.getElementById('cashAmount').value;
  
        fetch("/api/admin/sendcash", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({accountName: accountName, cashAmount: cashAmount})
        })
        .then(res => res.json())
        .then(data => 
            {
                if (data.resultCode !== config.resultCodeTable.SUCCESS)
                {
                    setSendCashFailed(true);
                }
                else
                {
                    setSendCashFailed(false);
                    setSendCashSuccess(data);
                }
            }
        )
    };
  
    return (
        <>
        {
            config.isAllowed(props, config.accountLevelCodeTable.moderator) && (
                <div className = "send__cash">
                    {
                        config.isEmptyObject(sendCashSuccess) ? (
                            <>
                                <h1>Account Name</h1>
                                <input type = "text" id = "accountName" placeholder = "Enter a valid account name. Capitalization matters!"/>
                                <input type = "number" id = "cashAmount" placeholder = "Enter desired amount to send"/>
                                <Button onClick = {PostSendCash}>Send</Button>
                                { sendCashFailed ? (<><br/><p>Account does not exist.</p></>) : (<br/>)}
                            </>
                        ) : (
                            <>
                                <p>{sendCashSuccess.results.message}</p>
                            </>
                        )
                    }
                
                </div>
            )
        }
      </>
    );
  }
