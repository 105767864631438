import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { BiLogInCircle } from 'react-icons/bi';
import Modal from './LoginModal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#F7AB00',
    width: '110px',
    color: '#000',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'all 0.2s ease',
      backgroundColor: '#F7AB00',
      color: '#000'
    }
  },
}));

export default function LoginButton({setAuth}) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className = {classes.button}
        startIcon={<BiLogInCircle color = "#000"/>}
        onClick={() => setOpen(true)}
      >
        Login
      </Button>
      <Modal open={open} onClose={() => setOpen(false)} setAuth = {setAuth} />
    </>
  );
}
