import React, { useState, useEffect } from 'react'
import header1 from '../images/header/compressed/dnopic1.jpg';
//import header2 from '../images/header/compressed/dnopic2.jpg';
import header3 from '../images/header/compressed/dnopic3.jpg';

import '../style/Header.css';

function Header() {
    const [selectedHeader, setSelectedHeader] = useState(null);
    
    useEffect(() => {
        function getRandomInt(max) {
            return Math.floor(Math.random() * max);
        }
        
        var images = [header1, header3];
        setSelectedHeader(images[getRandomInt(2)]);
    }, []);

    return (
        <div className="header__container">
            <div className="origins__banner">
                {selectedHeader && <img src={selectedHeader} alt="dno_header"/>}
            </div>
        </div>
    )
}

export default Header;
