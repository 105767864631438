import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import moment from 'moment';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Snackbar,
  useMediaQuery,
  Tabs,
  Tab,
  Box,
  Card,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import WorkIcon from '@material-ui/icons/Work';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EventIcon from '@material-ui/icons/Event';
import GroupIcon from '@material-ui/icons/Group';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BarChartIcon from '@material-ui/icons/BarChart';
import EditIcon from '@material-ui/icons/Edit';

import TradesTable from './tables/TradesTable';
import GoldLogsTable from './tables/GoldLogsTable';
import ItemLogsTable from './tables/ItemLogsTable';
import MailsSentTable from './tables/MailsSentTable';
import MailsReceivedTable from './tables/MailsReceivedTable';
import PurchasesTable from './tables/PurchasesTable';
import GiftsSentTable from './tables/GiftsSentTable';
import GiftsReceivedTable from './tables/GiftsReceivedTable';
import { jobCodes } from '../Data';

const config = require('../Config/Config');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Roboto, sans-serif',
    backgroundColor: '#1b1b1b',
    padding: theme.spacing(4, 0),
    minHeight: '100vh',
  },
  pageContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#ffffff',
    padding: theme.spacing(4),
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    textAlign: 'center',
    border: '2px solid #ffbb00',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#2c2c2c',
  },
  title: {
    color: '#ffbb00',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
  },
  searchInput: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    backgroundColor: '#3a3a3a',
    borderRadius: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
  },
  searchButton: {
    backgroundColor: '#ffbb00',
    color: '#000000',
    padding: theme.spacing(1, 4),
    '&:hover': {
      backgroundColor: '#e0a800',
    },
  },
  statsContainer: {
    marginBottom: theme.spacing(4),
    width: '100%',
    backgroundColor: '#3a3a3a',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
  },
  statCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#4a4a4a',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: '0px 2px 5px rgba(0,0,0,0.3)',
  },
  statIcon: {
    marginRight: theme.spacing(2),
    backgroundColor: '#ffbb00',
    color: '#000000',
  },
  statText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  statLabel: {
    fontSize: '0.9rem',
    color: '#cccccc',
  },
  statValue: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#ffffff',
  },
  editIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    color: '#ffbb00',
    '&:hover': {
      color: '#e0a800',
    },
  },
  tabsRoot: {
    backgroundColor: '#2c2c2c',
    color: '#ffffff',
    width: '100%',
  },
  tabPanel: {
    padding: theme.spacing(2),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
  noDataText: {
    color: '#ffffff',
    marginTop: theme.spacing(2),
  },
  dialogInput: {
    backgroundColor: '#3a3a3a',
    borderRadius: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiInputLabel-root': {
      color: '#ffffff',
    },
  },
  dialogButton: {
    backgroundColor: '#ffbb00',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#e0a800',
    },
  },
  smallText: {
    fontSize: '0.95rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  smallText2: {
    fontSize: '0.85rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  updateGoldDialogInput: {
    backgroundColor: '#3a3a3a',
    borderRadius: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiInputLabel-root': {
      color: '#ffffff',
    },
  },
  updateGoldDialogButton: {
    backgroundColor: '#ffbb00',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#e0a800',
    },
  },
  updateAccLevelDialogInput: {
    backgroundColor: '#3a3a3a',
    borderRadius: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiInputLabel-root': {
      color: '#ffffff',
    },
  },
  updateAccLevelDialogButton: {
    backgroundColor: '#ffbb00',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#e0a800',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`character-tabpanel-${index}`}
      aria-labelledby={`character-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const CharacterIdentification = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchQuery, setSearchQuery] = useState('');
  const [character, setCharacter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [tabValue, setTabValue] = useState(0);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newCharacterName, setNewCharacterName] = useState('');
  const [editLoading, setEditLoading] = useState(false);

  const [updateGoldDialogOpen, setUpdateGoldDialogOpen] = useState(false);
  const [newGold, setNewGold] = useState('');
  const [updateGoldLoading, setUpdateGoldLoading] = useState(false);

  const [updateAccLevelDialogOpen, setUpdateAccLevelDialogOpen] = useState(false);
  const [newAccLevelCode, setNewAccLevelCode] = useState('');
  const [updateAccLevelLoading, setUpdateAccLevelLoading] = useState(false);

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please enter either Character Name or ID.');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError(null);
    setCharacter(null);

    try {
      const isNumeric = /^\d+$/.test(searchQuery.trim());
      const queryParam = isNumeric
        ? `?characterID=${encodeURIComponent(searchQuery.trim())}`
        : `?characterName=${encodeURIComponent(searchQuery.trim())}`;

      const response = await fetch(`/api/admin/charid${queryParam}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (data.resultCode !== config.resultCodeTable.SUCCESS) {
        setError(data.results.error || 'Character not found.');
        setSnackbarSeverity('error');
        setSnackbarMessage(data.results.error || 'Character not found.');
        setSnackbarOpen(true);
      } else {
        const characterArray = data.results.character;

        if (!Array.isArray(characterArray)) {
          setError('Invalid data format received.');
          setSnackbarSeverity('error');
          setSnackbarMessage('Invalid data format received.');
          setSnackbarOpen(true);
        } else if (characterArray.length === 0) {
          setError('Character not found.');
          setSnackbarSeverity('error');
          setSnackbarMessage('Character not found.');
          setSnackbarOpen(true);
        } else {
          const characterData = {};
          characterArray.forEach((item) => {
            if (item.metadata && item.metadata.colName) {
              characterData[item.metadata.colName] = item.value;
            }
          });
          setCharacter(characterData);
        }
      }
    } catch (err) {
      console.error('Error fetching character data:', err);
      setError('An error has occurred. Please try again later.');
      setSnackbarSeverity('error');
      setSnackbarMessage('An error has occurred. Please try again later.');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const getJobName = (code) => {
    return jobCodes[code] || 'Unknown';
  };

  const handleEditClick = () => {
    setNewCharacterName(character.CharacterName);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setNewCharacterName('');
  };

  const handleNewNameChange = (e) => {
    setNewCharacterName(e.target.value);
  };

  const handleSaveNewName = async () => {
    if (!newCharacterName.trim()) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please enter a new character name.');
      setSnackbarOpen(true);
      return;
    }

    if (newCharacterName.length < 3 || newCharacterName.length > 16) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('New name must be between 3 and 16 characters.');
      setSnackbarOpen(true);
      return;
    }

    if (newCharacterName === character.CharacterName) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('New name must be different from the current name.');
      setSnackbarOpen(true);
      return;
    }

    setEditLoading(true);

    try {
      const response = await fetch('/api/admin/change-name', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          CharacterName: character.CharacterName,
          NewName: newCharacterName,
        }),
      });

      const data = await response.json();

      if (response.ok && data.resultCode === 200) {
        setSnackbarSeverity('success');
        setSnackbarMessage(data.results.message || 'Character name changed successfully.');
        setCharacter({ ...character, CharacterName: newCharacterName });
        setEditDialogOpen(false);
        setNewCharacterName('');
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(data.results.error || 'Failed to change character name.');
      }
    } catch (error) {
      console.error('Error changing character name:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('An error occurred. Please try again later.');
    } finally {
      setEditLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleUpdateGoldClick = () => {
    setNewGold(character.Gold ? (character.Gold).toString() : '0');
    setUpdateGoldDialogOpen(true);
  };

  const handleUpdateGoldClose = () => {
    setUpdateGoldDialogOpen(false);
    setNewGold('');
  };

  const handleNewGoldChange = (e) => {
    setNewGold(e.target.value);
  };

  const handleSaveNewGold = async () => {
    if (newGold === '') {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please enter a new gold amount.');
      setSnackbarOpen(true);
      return;
    }

    const parsedGold = parseInt(newGold, 10);
    if (isNaN(parsedGold) || parsedGold < 0) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('New gold amount must be a non-negative integer.');
      setSnackbarOpen(true);
      return;
    }

    setUpdateGoldLoading(true);

    try {
      const response = await fetch('/api/admin/update-gold', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          CharacterID: character.CharacterID,
          NewGold: parsedGold,
        }),
      });

      const data = await response.json();

      if (response.ok && data.resultCode === 200) {
        setSnackbarSeverity('success');
        setSnackbarMessage(data.results.message || "Character's gold updated successfully.");
        setCharacter({ ...character, Gold: parsedGold });
        setUpdateGoldDialogOpen(false);
        setNewGold('');
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(data.results.error || "Failed to update character's gold.");
      }
    } catch (error) {
      console.error('Error updating gold:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('An error occurred. Please try again later.');
    } finally {
      setUpdateGoldLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleUpdateAccLevelClick = () => {
    setNewAccLevelCode(character.AccountLevelCode.toString());
    setUpdateAccLevelDialogOpen(true);
  };

  const handleUpdateAccLevelClose = () => {
    setUpdateAccLevelDialogOpen(false);
    setNewAccLevelCode('');
  };

  const handleNewAccLevelCodeChange = (e) => {
    setNewAccLevelCode(e.target.value);
  };

  const handleSaveNewAccLevel = async () => {
    if (newAccLevelCode === '') {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please enter a new account level code.');
      setSnackbarOpen(true);
      return;
    }

    const parsedAccLevelCode = parseInt(newAccLevelCode, 10);
    if (isNaN(parsedAccLevelCode) || parsedAccLevelCode < 0) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('New account level code must be a non-negative integer.');
      setSnackbarOpen(true);
      return;
    }

    setUpdateAccLevelLoading(true);

    try {
      const response = await fetch('/api/admin/update-acclvl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          CharacterID: character.CharacterID,
          NewAccLevelCode: parsedAccLevelCode,
        }),
      });

      const data = await response.json();

      if (response.ok && data.resultCode === 200) {
        setSnackbarSeverity('success');
        setSnackbarMessage(data.results.message || "Character's account level updated successfully.");
        setCharacter({ ...character, AccountLevelCode: parsedAccLevelCode });
        setUpdateAccLevelDialogOpen(false);
        setNewAccLevelCode('');
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(data.results.error || "Failed to update character's account level.");
      }
    } catch (error) {
      console.error('Error updating account level:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('An error occurred. Please try again later.');
    } finally {
      setUpdateAccLevelLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <div className={classes.root}>
      <Container className={classes.pageContainer}>
        <Typography variant="h4" className={classes.title}>
          <SearchIcon fontSize="large" /> Character Identification
        </Typography>
        <div className={classes.searchContainer}>
          <TextField
            variant="outlined"
            placeholder="Enter Character Name or ID"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={classes.searchInput}
            InputProps={{
              style: { color: '#ffffff' },
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <Button
            variant="contained"
            className={classes.searchButton}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>

        {loading && (
          <div className={classes.loadingContainer}>
            <ReactLoading type="bubbles" color="#ffbb00" height={100} width={100} />
          </div>
        )}

        {error && (
          <Typography variant="h6" style={{ color: '#ff4d4d', marginBottom: theme.spacing(2) }}>
            {error}
          </Typography>
        )}

        {character && (
          <>
            <Paper className={classes.statsContainer}>
              <Grid container spacing={3}>
                {/* Character ID Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <FingerprintIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Character ID</span>
                      <span className={classes.statValue}>{character.CharacterID}</span>
                    </div>
                  </Card>
                </Grid>

                {/* Character Name Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <AccountCircleIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Character Name</span>
                      <Box display="flex" alignItems="center">
                        <span className={classes.statValue}>
                          {character.CharacterName}
                        </span>
                        <Tooltip title="Edit Character Name">
                          <EditIcon
                            className={classes.editIcon}
                            onClick={handleEditClick}
                            aria-label="edit character name"
                          />
                        </Tooltip>
                      </Box>
                    </div>
                  </Card>
                </Grid>

                {/* Account ID Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <GroupIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Account ID</span>
                      <span className={classes.statValue}>{character.AccountID}</span>
                    </div>
                  </Card>
                </Grid>

                {/* Account Name Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <GroupIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Account Name</span>
                      <span className={classes.statValue}>{character.AccountName}</span>
                    </div>
                  </Card>
                </Grid>

                {/* Level Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <EmojiEventsIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Level</span>
                      <span className={classes.statValue}>{character.CharacterLevel}</span>
                    </div>
                  </Card>
                </Grid>

                {/* Job Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <WorkIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Job</span>
                      <span className={classes.statValue}>
                        {character.JobCode} ({getJobName(character.JobCode)})
                      </span>
                    </div>
                  </Card>
                </Grid>

                {/* Account Level Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <BarChartIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Account Level</span>
                      <Box display="flex" alignItems="center">
                        <span className={`${classes.statValue}`}>
                          {character.AccountLevelCode}
                        </span>
                        <Tooltip title="Edit Account Level">
                          <EditIcon
                            className={classes.editIcon}
                            onClick={handleUpdateAccLevelClick}
                            aria-label="edit account level"
                          />
                        </Tooltip>
                      </Box>
                    </div>
                  </Card>
                </Grid>

                {/* Guild Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <GroupIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Guild</span>
                      <span className={classes.statValue}>{character.GuildName || 'None'}</span>
                    </div>
                  </Card>
                </Grid>

                {/* Current Gold Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <MonetizationOnIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Current Gold</span>
                      <Box display="flex" alignItems="center">
                        <span className={classes.statValue}>
                          {character.Gold ? `${Number(character.Gold).toLocaleString()}g` : '0g'}
                        </span>
                        <Tooltip title="Edit Current Gold (Inventory Only)">
                          <EditIcon
                            className={classes.editIcon}
                            onClick={handleUpdateGoldClick}
                            aria-label="edit current gold"
                          />
                        </Tooltip>
                      </Box>
                    </div>
                  </Card>
                </Grid>

                {/* Create Date Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <EventIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Create Date</span>
                      <span className={classes.statValue}>
                        {moment(character.CreateDate).format('DD-MM-YYYY HH:mm')}
                      </span>
                    </div>
                  </Card>
                </Grid>

                {/* Last Login Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <AccessTimeIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Last Login</span>
                      <span className={classes.statValue}>
                        {character.LastLoginDate
                          ? moment(character.LastLoginDate).format('DD-MM-YYYY HH:mm')
                          : 'Never'}
                      </span>
                    </div>
                  </Card>
                </Grid>

                {/* Last Logout Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card className={classes.statCard}>
                    <Avatar className={classes.statIcon}>
                      <ExitToAppIcon />
                    </Avatar>
                    <div className={classes.statText}>
                      <span className={classes.statLabel}>Last Logout</span>
                      <span className={classes.statValue}>
                        {character.LastLogoutDate
                          ? moment(character.LastLogoutDate).format('DD-MM-YYYY HH:mm')
                          : 'Never'}
                      </span>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Paper>

            <Paper className={classes.tabsRoot}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant={isMobile ? 'scrollable' : 'standard'}
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                centered={!isMobile}
              >
                <Tab label="Trades" />
                <Tab label="Gold Logs" />
                <Tab label="Item Logs" />
                <Tab label="Mails Sent" />
                <Tab label="Mails Received" />
                <Tab label="Purchases" />
                <Tab label="Gifts Sent" />
                <Tab label="Gifts Received" />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <TradesTable characterId={character.CharacterID} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <GoldLogsTable characterId={character.CharacterID} />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <ItemLogsTable characterId={character.CharacterID} />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <MailsSentTable characterId={character.CharacterID} />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <MailsReceivedTable characterId={character.CharacterID} />
              </TabPanel>
              <TabPanel value={tabValue} index={5}>
                <PurchasesTable characterId={character.CharacterID} />
              </TabPanel>
              <TabPanel value={tabValue} index={6}>
                <GiftsSentTable characterId={character.CharacterID} />
              </TabPanel>
              <TabPanel value={tabValue} index={7}>
                <GiftsReceivedTable characterId={character.CharacterID} />
              </TabPanel>
            </Paper>
          </>
        )}
      </Container>

      {/* Edit Character Name Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        aria-labelledby="edit-character-name-dialog"
      >
        <DialogTitle id="edit-character-name-dialog">Edit Character Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Character Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newCharacterName}
            onChange={handleNewNameChange}
            className={classes.dialogInput}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSaveNewName();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSaveNewName}
            color="primary"
            variant="contained"
            className={classes.dialogButton}
            disabled={editLoading}
          >
            {editLoading ? (
              <ReactLoading type="spin" color="#000000" height={20} width={20} />
            ) : (
              'Save'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update Gold Dialog */}
      <Dialog
        open={updateGoldDialogOpen}
        onClose={handleUpdateGoldClose}
        aria-labelledby="update-gold-dialog"
      >
        <DialogTitle id="update-gold-dialog">Update Current Gold</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Gold Amount"
            fullWidth
            variant="outlined"
            value={newGold}
            onChange={handleNewGoldChange}
            className={classes.updateGoldDialogInput}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSaveNewGold();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateGoldClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSaveNewGold}
            color="primary"
            variant="contained"
            className={classes.updateGoldDialogButton}
            disabled={updateGoldLoading}
          >
            {updateGoldLoading ? (
              <ReactLoading type="spin" color="#000000" height={20} width={20} />
            ) : (
              'Save'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update Account Level Dialog */}
      <Dialog
        open={updateAccLevelDialogOpen}
        onClose={handleUpdateAccLevelClose}
        aria-labelledby="update-acclvl-dialog"
      >
        <DialogTitle id="update-acclvl-dialog">Update Account Level</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Account Level Code"
            fullWidth
            variant="outlined"
            value={newAccLevelCode}
            onChange={handleNewAccLevelCodeChange}
            className={classes.updateAccLevelDialogInput}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSaveNewAccLevel();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateAccLevelClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSaveNewAccLevel}
            color="primary"
            variant="contained"
            className={classes.updateAccLevelDialogButton}
            disabled={updateAccLevelLoading}
          >
            {updateAccLevelLoading ? (
              <ReactLoading type="spin" color="#000000" height={20} width={20} />
            ) : (
              'Save'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CharacterIdentification;
