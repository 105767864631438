import React from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { BiLogOutCircle } from 'react-icons/bi';

var config = require('./Config/Config');

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#F7AB00',
    color: '#000',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'all 0.2s ease',
      backgroundColor: '#F7AB00',
      color: '#000'
    }
  },
}));

export default function LogoutButton(props) {
  const classes = useStyles();
  const history = useHistory();

  function logout()
  {
      fetch("/api/user/logout", {
          method: "GET",
      })
      .then(res => res.json())
      .then(data => 
        {
          if (data.resultCode === config.resultCodeTable.FAILURE)
          {
            props.setAuth({});
            history.push("/");
          }
        }
      )
  }
  return (
    <div>
      <Button
        onClick = {logout}
        variant = "contained"
        className = {classes.button}
        startIcon = {<BiLogOutCircle color = "#000"/>}
      >
        Logout
      </Button>
    </div>
  );
}