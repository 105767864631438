import React, { useState } from 'react';
import { Button, TextField, makeStyles, Collapse, IconButton } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RewardItem from './RewardItem';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    input: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const SendRewards = () => {
    const classes = useStyles();

    const [receiveTypeCode, setReceiveTypeCode] = useState(1);
    const [targetTypeCode, setTargetTypeCode] = useState(4);
    const [accountIdList, setAccountIdList] = useState("");
    const [accountNameList, setAccountNameList] = useState("");
    const [characterIdList, setCharacterIdList] = useState("");
    const [characterNameList, setCharacterNameList] = useState("");
    const [targetWorldId, setTargetWorldId] = useState(1);
    const [targetClassCode, setTargetClassCode] = useState("");
    const [targetMinLevel, setTargetMinLevel] = useState(1);
    const [targetMaxLevel, setTargetMaxLevel] = useState(70);
    const [sendDate, setSendDate] = useState(new Date().toISOString().slice(0, 19));
    const [expirationDate, setExpirationDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 3)).toISOString().slice(0, 19));
    const [eventName, setEventName] = useState("");
    const [senderName, setSenderName] = useState("DNO Staff");
    const [messageContent, setMessageContent] = useState("");
    const [goldReward, setGoldReward] = useState(0);
    const [eventRewardTypeCode, setEventRewardTypeCode] = useState(1);
    const [items, setItems] = useState([]);
    const [openItems, setOpenItems] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupSeverity, setPopupSeverity] = useState("success");
    
    const ItemToXML = (item) => {
        const {
            productflag, itemid, count, durability, randomseed,
            enhancelevel, potential, soulbound, seals, option,
            lifespan, eternity, dragonjeweltype
        } = item;

        if (!itemid || itemid === 0) return null;

        return `
            <Item 
                ProductFlag="${productflag}" 
                ItemID="${itemid}" 
                ItemCount="${count}" 
                ItemDurability="${durability}" 
                RandomSeed="${randomseed}" 
                ItemLevel="${enhancelevel}" 
                ItemPotential="${potential}" 
                SoulBoundFlag="${soulbound}" 
                SealCount="${seals}" 
                ItemOption="${option}" 
                ItemLifespan="${lifespan}" 
                EternityFlag="${eternity}" 
                DragonJewelType="${dragonjeweltype}" 
            />`;
    };

    const handleAddItem = () => {
        if (items.length > 20) return; 
        const newItem = {
            itemid: 0,
            productflag: 0,
            count: 1,
            durability: 0,
            randomseed: 0,
            enhancelevel: 0,
            potential: 0,
            soulbound: 1,
            seals: 0,
            option: 0,
            lifespan: 0,
            eternity: 1,
            dragonjeweltype: 0,
        };
        setItems([...items, newItem]);
        setOpenItems([...openItems, true]);
    };

    const handleChangeItem = (index, key, value) => {
        const newItems = [...items];
        newItems[index][key] = value;
        setItems(newItems);
    };

    const handleRemoveItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
        setOpenItems(openItems.filter((_, i) => i !== index));
    };

    const handleSubmit = () => {
        const validItemsXML = items
            .map(ItemToXML)
            .filter(Boolean)
            .join('');

        const eventRewardItemList = validItemsXML ? `<root>${validItemsXML}</root>` : null;

        const createXMLFromList = (list, head, subhead) => {
            if (!list) return null;
            
            const items = list.split(',').map(item => item.trim());
            if (items.length === 0) return null;
    
            return `<root>${items.map(item => `<${head} ${subhead}="${item}" />`).join('')}</root>`;
        };
    
        const body = JSON.stringify({
            receiveTypeCode: receiveTypeCode,
            targetTypeCode: targetTypeCode,
            accountIDList: createXMLFromList(accountIdList, "Account", "AccountID"),
            accountNameList: createXMLFromList(accountNameList, "Account", "AccountName"),
            characterIDList: createXMLFromList(characterIdList, "Character", "CharacterID"),
            characterNameList: createXMLFromList(characterNameList, "Character", "CharacterName"),
            targetWorldID: targetWorldId,
            targetClassCode: targetClassCode,
            targetMinLevel: targetMinLevel,
            targetMaxLevel: targetMaxLevel,
            sendDate: sendDate,
            expirationDate: expirationDate,
            eventName: eventName,
            senderName: senderName,
            messageContent: messageContent,
            goldReward: goldReward,
            eventRewardTypeCode: eventRewardTypeCode,
            eventRewardItemList: eventRewardItemList,
            eventRewardID: 0
        });
    
        fetch("/api/admin/sendrewards", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        })
        .then(res => res.json())
        .then(data => 
            {
                const resultCode = data.resultCode;
                const msg = data.results.message;
            
                if(resultCode === 200) 
                {
                    setPopupSeverity("success");
                    setPopupMessage("Rewards sent successfully!");
                } 
                else 
                {
                    setPopupSeverity("error");
                    setPopupMessage(`Error sending rewards: ${msg}`);
                }
            
                setPopupOpen(true);
            }
        )
    };

    return (
        <div className="send__rewards">
            <h1 className="send__rewards-title">Send Rewards</h1>
            <TextField className={classes.input} value={receiveTypeCode} onChange={e => setReceiveTypeCode(e.target.value)} label="Receive Type Code 1 = all items, 2 = select 1 item" type="number" variant="outlined" />
            <TextField className={classes.input} value={targetTypeCode} onChange={e => setTargetTypeCode(e.target.value)} label="Target Type Code 1 = all accounts, 2 = list of accounts, 3 = all characters, 4 = list of characters" type="number" variant="outlined" />
            <TextField className={classes.input} value={accountIdList} onChange={e => setAccountIdList(e.target.value)} label="Account ID List (comma separated)" variant="outlined" />
            <TextField className={classes.input} value={accountNameList} onChange={e => setAccountNameList(e.target.value)} label="Account Name List (comma separated)" variant="outlined" />
            <TextField className={classes.input} value={characterIdList} onChange={e => setCharacterIdList(e.target.value)} label="Character ID List (comma separated)" variant="outlined" />
            <TextField className={classes.input} value={characterNameList} onChange={e => setCharacterNameList(e.target.value)} label="Character Name List (comma separated)" variant="outlined" />
            <TextField className={classes.input} value={targetWorldId} onChange={e => setTargetWorldId(e.target.value)} label="Target World ID" type="number" variant="outlined" />
            <TextField className={classes.input} value={targetClassCode} onChange={e => setTargetClassCode(e.target.value)} label="Target Class Code" variant="outlined" />
            <TextField className={classes.input} value={targetMinLevel} onChange={e => setTargetMinLevel(e.target.value)} label="Target Min Level" type="number" variant="outlined" />
            <TextField className={classes.input} value={targetMaxLevel} onChange={e => setTargetMaxLevel(e.target.value)} label="Target Max Level" type="number" variant="outlined" />
            <TextField className={classes.input} value={sendDate} onChange={e => setSendDate(e.target.value)} label="Send Date" type="datetime-local" variant="outlined" />
            <TextField className={classes.input} value={expirationDate} onChange={e => setExpirationDate(e.target.value)} label="Expiration Date" type="datetime-local" variant="outlined" />
            <TextField className={classes.input} value={eventName} onChange={e => setEventName(e.target.value)} label="Event Name" variant="outlined" />
            <TextField className={classes.input} value={senderName} onChange={e => setSenderName(e.target.value)} label="Sender Name" variant="outlined" />
            <TextField className={classes.input} value={messageContent} onChange={e => setMessageContent(e.target.value)} label="Message Content" multiline minRows={4} variant="outlined" />
            <TextField className={classes.input} value={goldReward} onChange={e => setGoldReward(e.target.value)} label="Gold Reward" type="number" variant="outlined" />
            <TextField className={classes.input} value={eventRewardTypeCode} onChange={e => setEventRewardTypeCode(e.target.value)} label="Event Reward Type Code 1 = GM, 2 = System" type="number" variant="outlined" />

            <Button className={classes.button} variant="contained" color="primary" onClick={handleAddItem}>Add Item</Button>
            {items.map((item, index) => (
                <div key={index}>
                    <IconButton
                        onClick={() => {
                            const newOpenItems = [...openItems];
                            newOpenItems[index] = !newOpenItems[index];
                            setOpenItems(newOpenItems);
                        }}
                    >
                        {openItems[index] ? 
                            <ExpandLessIcon color="secondary" /> : 
                            <ExpandMoreIcon color="secondary" />
                        }
                    </IconButton>
                    <span>{`Item ${index + 1}`}</span>
                    <Collapse in={openItems[index]}>
                        <RewardItem 
                            item={item} 
                            index={index} 
                            handleChangeItem={handleChangeItem} 
                            handleRemoveItem={handleRemoveItem}
                        />
                    </Collapse>
                </div>
            ))}
            <Button className={classes.button} variant="contained" color="secondary" onClick={handleSubmit} disabled = {items.length <= 0}>Send Rewards</Button>
            <Snackbar 
            open={popupOpen} 
            autoHideDuration={3000} 
            onClose={() => setPopupOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
            <Alert onClose={() => setPopupOpen(false)} severity={popupSeverity} elevation={6} variant="filled">
                {popupMessage}
            </Alert>
            </Snackbar>
        </div>
    );
};

export default SendRewards;
