import React from 'react'
import '../style/NotFound.css';
function PageNotFound() {
    return (
        <>
            <div className = "page__notfound">
                <p style = {{color: "white"}}>The page you requested cannot be accessed or cannot be found.</p>
            </div>
        </>
    )
}

export default PageNotFound
