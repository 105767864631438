import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  CircularProgress,
  useTheme
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const config = require('../Config/Config');

export default function Modal({ open, onClose, setAuth }) {
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupSeverity, setPopupSeverity] = useState("success");

  const handleSubmitOnEnter = (event, actionFunction) => {
    if (event.key === 'Enter') {
      actionFunction();
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    setErrorMessage('');

    if (!username || !password) 
    {
      setErrorMessage('Username or password cannot be empty.');
      setLoading(false);
      return;
    } 

    fetch("/api/user/login", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    })
    .then(res => res.json())
    .then(data => 
      {
        if (data.resultCode === config.resultCodeTable.FAILURE)
        {
          setErrorMessage("Incorrect credentials");
        }
        setLoading(false);
        setAuth(data);
      }
    )
  };

  const handleResetPassword = () => {
    setLoading(true);
    setErrorMessage('');
    if (!username) 
    {
      setErrorMessage('Username cannot be empty.');
      setLoading(false);
      return;
    } 

    fetch("/api/user/resetpassrequest", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accountInput: username
        })
    })
    .then(res => res.json())
    .then(data => 
      {
        if(data.resultCode === config.resultCodeTable.FAILURE)
        {
          setPopupSeverity("error");
          setPopupMessage(data.results.error.message);
        }
        else
        {
          setPopupSeverity("success");
          setPopupMessage(data.results.message);
        }
        setPopupOpen(true);
        setLoading(false);
      }
    )
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      {isMobile && (
        <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            style={{ position: 'absolute', right: 5, top: 5, zIndex: 1000 }}
        >
            <CloseIcon />
        </IconButton>
      )}
      <DialogTitle style={{ color: theme.palette.secondary.main }}>
        {isResetPassword ? "Reset Password" : "Login"}
      </DialogTitle>
      <DialogContent>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          label={isResetPassword ? "Username or Email" : "Username"}
          value={username}
          onChange={e => setUsername(e.target.value)}
          onKeyPress={e => handleSubmitOnEnter(e, isResetPassword ? handleResetPassword : handleSubmit)}
        />
        {!isResetPassword && (
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            label="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyPress={e => handleSubmitOnEnter(e, handleSubmit)}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: 20, position: 'relative' }}
          disabled={loading}
          onClick={isResetPassword ? handleResetPassword : handleSubmit}
        >
          {isResetPassword ? "Reset Password" : "Login"}
          {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
        </Button>
        <Typography
          style={{ marginTop: 20, cursor: 'pointer' }}
          color="secondary"
          onClick={() => setIsResetPassword(!isResetPassword)}
        >
          {isResetPassword ? "Go back to Login" : "Forgot password?"}
        </Typography>
      </DialogContent>
      <Snackbar 
        open={popupOpen} 
        autoHideDuration={3000} 
        onClose={() => setPopupOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
        <Alert onClose={() => setPopupOpen(false)} severity={popupSeverity} elevation={6} variant="filled">
            {popupMessage}
        </Alert>
        </Snackbar>
    </Dialog>
  );
}